import {CBARTexture} from "./CBARTexture";
import {CBARImage} from "./CBARImage";
import * as THREE from "three";

export class CBARMaskTexture extends CBARTexture {
    private displayCanvas = document.createElement("canvas");

    loadImage(image:CBARImage) : boolean {
        const success = super.loadImage(image);
        if (success && this.threeTexture && this.canvas) {
            this.threeTexture.format = THREE.LuminanceFormat;

            this.displayCanvas.width = this.canvas.width;
            this.displayCanvas.height = this.canvas.height;

            const ctx = this.displayCanvas.getContext("2d");
            if (ctx) {
                ctx.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height);
            }

            this.threeTexture.image = this.displayCanvas;
        }

        return success
    }

    private _isEditing = false;

    public blendResults = true;

    get isEditing() {
        return this._isEditing;
    }

    set isEditing(val:boolean) {
        if (this._isEditing === val) return;

        this._isEditing = val;

        if (this.threeTexture) {
            this.threeTexture.image = this._isEditing || !this.blendResults ? this.canvas : this.displayCanvas;
            this.threeTexture.needsUpdate = true;
        }
    }

    public applyBlend() {
        if (!this.canvas || !this.blendResults) return;

        let mask = cv.imread(this.canvas);

        const blurAmount = Math.min(Math.max(1 + 2 * Math.round(Math.hypot(mask.cols, mask.rows) / 1000), 3), 9);
        const dilateAmount = Math.ceil(Math.max(2 * blurAmount / 3, 3));
        cv.dilate(mask, mask, cv.getStructuringElement(cv.MORPH_ELLIPSE, new cv.Size(dilateAmount, dilateAmount)));
        cv.GaussianBlur(mask, mask, new cv.Size(blurAmount,blurAmount), 0);

        cv.imshow(this.displayCanvas, mask);
        mask.delete();

        if (this.threeTexture) {
            this.threeTexture.needsUpdate = true;
        }
    }
}