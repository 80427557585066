import {BrowserProperties, BrowserType} from "react-client-info";

export type ZoomableProperties = {
    canZoom?:boolean
    maxScale?:number
    snapRange?: number;
    initialScale?:number
    onZoomStarted?:(state:ZoomableState)=>void
    onZoomChanged?:(state:ZoomableState)=>void
    onZoomCompleted?:(state:ZoomableState)=>void

    canPan?:boolean
    onPanStarted?:(state:ZoomableState)=>void
    onPanChanged?:(state:ZoomableState)=>void
    onPanCompleted?:(state:ZoomableState)=>void
}

export type ZoomableState = {
    scale: number
    posX: number
    posY: number

    offsetX:number
    offsetY:number

    startScale: number
    startX: number
    startY: number

    isDragging: boolean
    isZooming: boolean
    snapToTimeout?:number
}

type DerivedZoomableState = ZoomableState & {
    listeners:{[listener: string]: any}
    gestureStartScale: number
    touchStartWidth: number
    tappedTwice: boolean
    minScale:number
}

let elements = new Map<ZoomableElement, DerivedZoomableState>();

function getState(element:ZoomableElement):DerivedZoomableState  {
    const state = elements.get(element);
    if (state) {
        //remove all existing listeners
        for (let type in state.listeners) {
            const listener = state.listeners[type];
            element.removeEventListener(type, listener)
        }
        return state
    }

    return {
        listeners:{},
        gestureStartScale: 0,
        minScale: element.settings.initialScale ? element.settings.initialScale : 1.0,
        scale: element.settings.initialScale ? element.settings.initialScale : 1.0,

        touchStartWidth: 0,
        tappedTwice: false,

        //Movement
        isDragging: false,
        isZooming: false,
        posX: 0,
        posY: 0,
        offsetX: 0,
        offsetY: 0,
        startScale: 1,
        startX: 0,
        startY: 0,
    }

}

export interface ZoomableRect {
    height: number;
    width: number;
    x: number;
    y: number;
}

export function equals(a:ZoomableRect|undefined, b:ZoomableRect|undefined):boolean {
    if (!a || !b) {
        return !a == !b;
    }
    return a.width === b.width && a.height === b.height && a.x === b.x && a.y === b.y;
}

type ZoomableElement = HTMLElement & {
    client:BrowserProperties
    settings:ZoomableProperties
}

export function Zoomable(element:HTMLElement, client:BrowserProperties, settings?:ZoomableProperties) {
    (element as any).client = client;
    (element as any).settings = settings;

    _Zoomable(element as ZoomableElement)
}

function _Zoomable(element:ZoomableElement) {

    let zoomRate = 0.001;
    if (element.client.hasTouchpad || element.client.isMobile) {
        zoomRate = 0.01
    } else if (element.client.browser === BrowserType.Firefox) {
        zoomRate = 0.1
    }
    const maxScale = element.settings.maxScale ? element.settings.maxScale : 2.5;

    let state = getState(element);

    let lastScale = state.scale;
    let autoScalingHandler = 0;

    moveElements()

    //add and keep track of listeners
    function addEventListener(element:HTMLElement, type: string, listener: any) {
        element.addEventListener(type, listener);
        state.listeners[type] = listener
    }

    //add event listeners
    if (element.client.isMobile) {
        addEventListener(element,"touchstart", handleTouchStart);
        addEventListener(element,"touchmove", handleTouchMove);
        addEventListener(element,"touchend", handleTouchEnd)
    } else {

        if (element.client.hasGestureSupport) {
            addEventListener(element, "gesturestart", handleGestureStart);
            addEventListener(element,"gesturechange", handleGestureChange);
            addEventListener(element,"gestureend", handleGestureEnd);
        } else {
            addEventListener(element,'wheel', handleWheelEvent);
        }

        addEventListener(element,"mousedown", handleMouseDown);
        addEventListener(element,"mousemove", handleMouseMove);
        addEventListener(element,"mouseup", handleMouseUp);
        addEventListener(element,"mouseleave", handleMouseCancel);
        addEventListener(element,"mouseout", handleMouseCancel);
    }

    elements.set(element, state);

    function handleMouseDown(e: MouseEvent) {
        e.preventDefault();
        dragStart(e)
    }

    function handleMouseMove(e: MouseEvent) {
        e.preventDefault();
        dragMove(e)
    }

    function handleMouseUp(e: MouseEvent) {
        e.preventDefault();
        dragEnd()
    }

    function handleMouseCancel(e: MouseEvent) {
        e.preventDefault();
        dragEnd()
    }

    function onZoomStarted() {
        if (element.settings.maxScale === state.minScale) return;

        if (element.settings.onZoomStarted) {
            element.settings.onZoomStarted(state)
        }

        state.startScale = state.scale;
        //console.log(`Zoom started at (${state.startX}, ${state.startY})`)
        state.isZooming = true
    }

    function onZoomChanged() {
        if (element.settings.maxScale === state.minScale) return;

        if (element.settings.onZoomChanged) {
            element.settings.onZoomChanged(state)
        }
    }

    function onZoomCompleted() {
        if (element.settings.maxScale === state.minScale) return;

        if (element.settings.onZoomCompleted) {
            element.settings.onZoomCompleted(state)
        }

        //console.log("Zoom completed");
        state.isZooming = false
    }

    //Mouse events
    function handleWheelEvent(e: WheelEvent) {
        if (!element.settings.canZoom) return;
        e.preventDefault();

        if (e.ctrlKey || !element.client.hasGestureSupport) {
            //const isChromeTwoFingers = e.deltaY === 0 && e.deltaX === 0 && element.client.browser === BrowserType.Chrome && element.client.hasTouchpad;
            if (e.deltaY === 0 && e.deltaX === 0 && element.client.browser === BrowserType.Chrome && element.client.hasTouchpad) {
                twoFingerTap() //two finger tap works like this on mac trackpads in chrome
            } else {
                state.scale = restrictScale(state.scale - e.deltaY * zoomRate);

                if (!state.isDragging && !state.isZooming) {
                    state.startX = e.clientX - state.posX;
                    state.startY = e.clientY - state.posY;
                    onZoomStarted()
                }
            }

            adjustZoomPosition(e.clientX, e.clientY);

        } else {
            state.posX =  state.posX - e.deltaX * 2 + state.startX;
            state.posY = state.posY - e.deltaY * 2 + state.startY;
        }

        moveElements();
    }

    //Mobile touch events:
    function handleTouchStart(e: TouchEvent) {
        //e.preventDefault();

        if (state.isZooming) return;

        if (e.targetTouches.length === 2) {

            const xPos = (e.touches[0].clientX + e.touches[1].clientX) / 2.0;
            const yPos = (e.touches[0].clientY + e.touches[1].clientY) / 2.0;

            state.startX = xPos - state.posX;
            state.startY = yPos - state.posY;

            state.touchStartWidth = Math.hypot(e.touches[1].clientX - e.touches[0].clientX, e.touches[1].clientY - e.touches[0].clientY);
            state.gestureStartScale = restrictScale(state.scale);

            twoFingerStart()
        } else if (element.settings.canPan !== false && !state.isZooming) {
            state.isDragging = true;
            state.startX = e.touches[0].pageX - state.posX;
            state.startY = e.touches[0].pageY - state.posY;

            if (element.settings.onPanStarted) {
                element.settings.onPanStarted(state)
            }
        }
    }

    function handleTouchMove(e: TouchEvent) {
        //e.preventDefault();

        if (autoScalingHandler) return;

        if (e.targetTouches.length === 2) {
            state.tappedTwice = false;

            const xPos = (e.touches[0].clientX + e.touches[1].clientX) / 2.0;
            const yPos = (e.touches[0].clientY + e.touches[1].clientY) / 2.0;

            const touchWidth = Math.hypot(e.touches[1].clientX - e.touches[0].clientX, e.touches[1].clientY - e.touches[0].clientY);
            const scaleNow = touchWidth / state.touchStartWidth;
            state.scale = restrictScale(state.gestureStartScale * scaleNow);

            state.posX = xPos - state.startX;
            state.posY = yPos - state.startY

        } else if (element.settings.canPan !== false && state.isDragging) {
            const xPos = e.touches[0].clientX;
            const yPos = e.touches[0].clientY;
            state.posX = xPos - state.startX;
            state.posY = yPos - state.startY;

            if (element.settings.onPanChanged) {
                element.settings.onPanChanged(state)
            }
        }

        moveElements();
    }

    function handleTouchEnd(e: TouchEvent) {
        e.preventDefault();

        if (state.isZooming) {
            window.setTimeout(()=> {
                state.isZooming = false;
                onZoomCompleted()
            }, 100)
        }

        if (state.isDragging && element.settings.onPanCompleted) {
            element.settings.onPanCompleted(state)
        }

        state.isDragging = false
    }

    //Gesture events
    function handleGestureStart(e: Event) {
        e.preventDefault();

        const event = e as MouseEvent;
        if (event) {
            state.startX = event.pageX - state.posX;
            state.startY = event.pageY - state.posY;
        }

        state.gestureStartScale = restrictScale(state.scale);

        twoFingerStart()
    }

    function handleGestureChange(e: Event) {
        e.preventDefault();

        if (autoScalingHandler) return;

        const event = e as MouseEvent;

        if (event) {
            state.scale = restrictScale(state.gestureStartScale * (event as any).scale);

            state.posX = event.pageX;
            state.posY = event.pageY;

            adjustZoomPosition(event.clientX, event.clientY)
        }

        moveElements();
    }

    function handleGestureEnd(e: Event) {
        e.preventDefault();
    }

    function dragStart(e: MouseEvent) {

        if (autoScalingHandler || e.ctrlKey || element.settings.canPan === false) return;

        if (state.isZooming) {
            onZoomCompleted()
        }

        //console.log("Drag started")
        state.isDragging = true;
        state.startX = e.pageX - state.posX;
        state.startY = e.pageY - state.posY;

        if (element.settings.onPanStarted) {
            element.settings.onPanStarted(state)
        }
    }

    function dragMove(e: MouseEvent) {
        if (state.isZooming) {
            onZoomCompleted()
        }

        if (state.isDragging) {
            state.posX = e.pageX;
            state.posY = e.pageY;
            if (element.settings.onPanChanged) {
                element.settings.onPanChanged(state)
            }
            moveElements()
        }
    }

    function dragEnd() {
        if (!state.isDragging) return;
        state.isDragging = false;

        //console.log("Drag completed")

        if (element.settings.onPanCompleted) {
            element.settings.onPanCompleted(state)
        }
    }

    //Logic functions
    function restrictScale(value: number): number {
        return Math.max(1.0, Math.min(maxScale, value));
    }

    function moveElements() {
        window.requestAnimationFrame(() => {
            element.style.transform = `translate3D(${state.posX}px, ${state.posY}px, 0px) scale(${state.scale})`
        });

        if (state.isZooming && lastScale !== state.scale) {
            onZoomChanged()
        }

        if (element.settings.snapRange || !element.settings.canZoom) {
            const snapRange = element.settings.snapRange;
            if (state.snapToTimeout) {
                window.clearTimeout(state.snapToTimeout);
            }

            state.snapToTimeout = window.setTimeout(() => {
                if (element.settings.initialScale && (!element.settings.canZoom || (snapRange && Math.abs(state.scale - element.settings.initialScale) < snapRange))) {
                    state.scale = element.settings.initialScale;
                    state.posX = 0;
                    state.posY = 0;
                    moveElements()
                }
            }, 250);
        }

        lastScale = state.scale
    }

    function adjustZoomPosition(xPos:number, yPos: number) {
        if (!element.settings.canZoom) return;

        const rect = element.parentElement!.getBoundingClientRect();

        const distX = (rect.left - xPos + rect.width / 2.0);
        const distY = (rect.top - yPos + rect.height / 2.0);
        state.posX = state.offsetX - distX * (1 - state.scale);
        state.posY = state.offsetY + state.scale - distY * (1 - state.scale)
    }

    function twoFingerStart() {

        if (!state.isDragging && !state.isZooming) {
            onZoomStarted()
        }

        if (!state.tappedTwice) {
            state.tappedTwice = true;
            setTimeout(function () {
                state.tappedTwice = false;
            }, 500);
        } else {
            twoFingerTap()
        }
    }

    function twoFingerTap() {
        if (autoScalingHandler !== 0) return;
        const endScale = state.scale < maxScale / 2 ? maxScale : 1;
        autoScalingHandler = window.setInterval(() => {
            state.scale = (endScale + state.scale) / 2.0;
            moveElements()
        }, 50);
        setTimeout(function () {
            clearInterval(autoScalingHandler);
            autoScalingHandler = 0;
            state.scale = endScale;
            moveElements()
        }, 500);
    }
}