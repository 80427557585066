import * as React from "react"
import {createRef, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {CBARMediaInfo, CBARMediaView, equals, Zoomable, ZoomableRect} from "./internal";
import * as THREE from "three";
import {OrthographicCamera} from "three";
import {CBARLogLevel, DeferredExecutor, log, normalizedToScreen} from "./internal/Utils";
import {BrowserProperties, WebClientInfo} from "react-client-info";
import {ResizeObserver as Polyfill} from '@juggle/resize-observer';
import {CBARContext, CBARReceiver, ImageExportOptions, isDrawingTool} from "./CBARContext";
import {
    CBAREventType,
    CBARIntersection,
    CBARMode,
    CBARMouseEvent,
    CBARRenderContext,
    CBARSurfaceType,
    CBARToolMode
} from "./CBARTypes";
import {CBARScene, CBARSceneProperties} from "./components";
import {CBARAmbientLight, CBARDirectionalLight} from "./assets";
import {CBARObject3D} from "./components";

const ResizeObserver = (window as any).ResizeObserver || Polyfill;

interface CBARViewProps {
    className?:string,
    onContextCreated:(context:CBARContext)=>void,
    toolMode?:CBARToolMode
}

const SHADOWS_ENABLED = true;

export const isTouchDevice = 'ontouchstart' in window || !!navigator.msMaxTouchPoints;

export function CBARView(props: CBARViewProps) {

    const [renderContext, setRenderContext] = useState<CBARRenderContext>();
    const [receiver, setReceiver] = useState<CBARReceiver>();

    const context = useMemo(()=>{
        if (renderContext && receiver) {
            log(`react-home-ar (0.0.2070) by cambrian\ncambrian.io`, CBARLogLevel.MessageClient);
            return new CBARContext(renderContext, receiver)
        }
    }, [renderContext, receiver])

    const [browserProperties, setBrowserProperties] = useState<BrowserProperties>();
    const [mediaProperties, setMediaProperties] = useState<CBARMediaInfo>();

    const doRender = useRef<boolean>(false);

    const [scene, setScene] = useState<CBARScene>();

    const [contentRect, setContentRect] = useState<ClientRect|DOMRect>();
    const observer = React.useRef(new ResizeObserver((entries:any) => {
        setContentRect(entries[0].contentRect);
        setNeedsRefresh(true);
    }));
    const [crop, setCrop] = useState<ZoomableRect>();
    const resolution = useRef(new THREE.Vector2())

    const primaryContainer = createRef<HTMLDivElement>();
    const container = createRef<HTMLDivElement>();
    const canvas = createRef<HTMLCanvasElement>();
    const drawingOverlay = createRef<HTMLDivElement>();

    const media = useRef<CBARMediaView>();
    const raycaster = new THREE.Raycaster();

    const camera = new THREE.PerspectiveCamera( 43.1, 1280 / 720, 0.1, 1000 );
    const sceneJS = new THREE.Scene();

    const [viewSize, setViewSize] = useState<[number, number]>();

    const screenshotExecutor = useRef<DeferredExecutor<string>>();
    const screenshotOptions = useRef<ImageExportOptions>();

    const toolMode = props.toolMode ? props.toolMode : CBARToolMode.None;

    const [needsRefresh, setNeedsRefresh] = useState(false);
    const mounted = useRef<boolean>();
    const [pixelFactor, setPixelFactor] = useState(1.0);

    const refresh = useCallback(()=>{
        doRender.current = true;
    }, [doRender])

    const tick = useCallback((canvas:HTMLCanvasElement) => {
        if (!mounted.current || !canvas) return;

        requestAnimationFrame(()=>tick(canvas));

        if (!media.current || !scene || (!doRender.current && !screenshotExecutor.current)) {
            return;
        }

        scene.context.gl.resolution.set(scene.context.gl.renderer.domElement.width, scene.context.gl.renderer.domElement.height)
        media.current.update();

        scene.render();

        if (screenshotExecutor.current) {
            const options = screenshotOptions.current ? screenshotOptions.current : {format:'jpeg', quality:90.0};
            console.log(`Exporting ${options.format} image, dimensions ${canvas.width}x${canvas.height}`);
            const screenshot = canvas.toDataURL(`image/${options.format}`, options.quality);
            if (screenshot.length > 15000) {
                screenshotExecutor.current.resolve(screenshot)
            } else {
                screenshotExecutor.current.reject(new Error("Invalid screenshot"));
            }
            screenshotExecutor.current = undefined;
        }

        doRender.current = media.current.mode === CBARMode.Video;

    }, [mounted, media, scene, screenshotExecutor, screenshotOptions, requestAnimationFrame, doRender]);

    useEffect(() => {
        if (!mounted.current || !scene) return;
        scene.toolModeChanged(toolMode);
    }, [mounted, toolMode, scene]);

    useEffect(()=>{
        if (!mounted.current) return;
        if (needsRefresh) {
            doRender.current = true;
            setNeedsRefresh(false);
        }
    }, [mounted, needsRefresh]);

    useEffect(() => {
        if (!mounted.current) return;

        if (container.current && viewSize && contentRect && browserProperties) {

            const xPos = (contentRect.width - viewSize[0]) / 2.0;
            const yPos = (contentRect.height - viewSize[1]) / 2.0;
            const newCrop = {x:xPos, y:yPos, width:contentRect.width, height:contentRect.height};

            if (!equals(crop, newCrop)) {

                setCrop(newCrop);

                container.current.style.width = `${viewSize[0]}px`;
                container.current.style.height = `${viewSize[1]}px`;

                container.current.style.left = `${xPos}px`;
                container.current.style.top = `${yPos}px`;
                container.current.style.visibility = "visible";

                const scaledNoPadding = Math.max((1 + contentRect.width) / viewSize[0], (1 + contentRect.height) / viewSize[1]);
                setPixelFactor(Math.max(1.0, scaledNoPadding));
                Zoomable(container.current, browserProperties, {canPan:false, canZoom:false, initialScale:scaledNoPadding, snapRange:0.15});

                setNeedsRefresh(true);
            }
            //console.log("browserProperties", browserProperties, "viewSize:", viewSize);
        }
    }, [mounted, container, viewSize, contentRect, browserProperties, setNeedsRefresh]);

    useEffect(() => {
        if (!mounted.current) return;
        if (mediaProperties && contentRect) {
            const mediaAspectRatio = mediaProperties.width / mediaProperties.height;
            const viewAspectRatio = contentRect.width / contentRect.height;

            const width = (mediaAspectRatio > viewAspectRatio) ? contentRect.width : contentRect.height * mediaAspectRatio;
            const height = (mediaAspectRatio > viewAspectRatio) ? contentRect.width / mediaAspectRatio : contentRect.height;

            setViewSize([width * window.devicePixelRatio, height * window.devicePixelRatio]);
            //console.log(`Media size is ${mediaProperties.width} x ${mediaProperties.height}, resizing view to ${width} x ${height}`);

            setNeedsRefresh(true);
        }
    }, [mounted, mediaProperties, contentRect]);

    useEffect(() => {
        if (!mounted.current) return;
        if (context && viewSize && pixelFactor) {
            context.gl.renderer.setSize(viewSize[0], viewSize[1]);
            const maxDimension = Math.max(viewSize[0], viewSize[1]);
            const pixelRatio = window.devicePixelRatio * pixelFactor;
            const maxTextureDimension = Math.min(4096, context.gl.renderer.capabilities.maxTextureSize / 4);
            const factor = Math.min(maxTextureDimension / (pixelRatio * maxDimension), 1.0);
            //console.log("maxTextureDimensions", maxTextureDimensions);
            context.gl.renderer.setPixelRatio(pixelRatio * factor);
            //console.log(`Rendering at ${viewSize[0]}x${viewSize[1]} at density ${pixelRatio}`)
        }
    }, [mounted, context, viewSize, pixelFactor]);

    useEffect(() => {
        if (primaryContainer.current) {
            observer.current.observe(primaryContainer.current);
        }
        return () => {
            if (observer.current && primaryContainer.current) {
                observer.current.unobserve(primaryContainer.current);
            }
        };
    }, [primaryContainer, observer]);

    const startVideoCamera = useCallback((context, tracking, facing) => {
        const _media = media.current!;
        if (scene) {
            return _media.startVideoCamera(tracking, facing);
        } else {
            return new Promise<void>((resolve,reject)=>{
                setSceneResolver({
                    resolve:() => {
                        _media.startVideoCamera(tracking, facing).then(()=>{
                            resolve();
                        }).catch(error=>{
                            reject(error);
                        });
                    },
                    reject
                })
                setScene(new CBARScene(context));
            })
        }
    }, [media, scene]);

    const stopVideoCamera = useCallback(() => {
        return media.current!.stopVideoCamera();
    }, [media]);

    const captureImage = useCallback(() => {
        return media.current!.captureImage()
    }, [media]);

    const captureScreenshot = useCallback((context:CBARContext, options?:ImageExportOptions) => {
        return new Promise<string>((resolve, reject) => {
            screenshotOptions.current = options;
            screenshotExecutor.current = {resolve, reject};
            doRender.current = true;
        });
    }, [screenshotExecutor, screenshotOptions, doRender]);

    const loadImage = useCallback((context:CBARContext, image:HTMLImageElement) => {
        if (media.current) {
            media.current.loadImage(image)
        }
    }, [media.current]);

    //const [directionalLight, setDirectionalLight] = useState<CBARDirectionalLight>();

    const raycast = useCallback((normalizedPoint:THREE.Vector2, searchObjects?:THREE.Object3D[])=>{

        const intersections: CBARIntersection[] = [];

        if (!context || !scene) return intersections;

        raycaster.setFromCamera(normalizedToScreen(normalizedPoint), context.gl.camera);

        if (!searchObjects) {
            searchObjects = scene.getEventObjects(CBAREventType.TouchDown);
        }

        const seen = new Set<string>();

        const intersects = raycaster
            .intersectObjects(searchObjects, true)
            .filter(item => {
                const id = makeId(item);
                if (seen.has(id)) return false;
                seen.add(id);
                return true
            });

        for (const intersect of intersects) {
            let eventObject: THREE.Object3D = intersect.object;

            let cbarObject: CBARObject3D<any>|undefined;
            while (eventObject.parent) {
                let obj = (eventObject as any).__cbarObject as CBARObject3D<any>|undefined;

                if (obj && obj.rootObject().receivesEvents && (obj.rootObject().existsAtPoint(normalizedPoint))) {
                    cbarObject = obj.rootObject();
                    break
                }

                eventObject = eventObject.parent
            }

            if (cbarObject) {
                //console.log("event: " + cbarObject.rootObject().name);
                intersections.push({
                    intersection:intersect,
                    object:cbarObject
                })
            }
        }

        return intersections;

    }, [context, scene, raycaster])

    const [directionalLight, setDirectionalLight] = useState<CBARDirectionalLight>();

    useEffect(()=>{
        if (directionalLight && scene && camera && directionalLight.position.length() === 0.0) {
            const direction = new THREE.Vector3(3, 10, -5);
            directionalLight.position = direction.project(camera);
        }
    }, [directionalLight, scene]);

    useEffect(() => {

        if (scene && context) {
            if (scene.backgroundImage) {
                loadImage(context, scene.backgroundImage.image)
            }

            // //minimum level
            new CBARAmbientLight(context).load(undefined, {
                "intensity": 1.0,
                "color": "0xffffff"
            }).then((asset)=>{
                scene.assets.add(asset);
            });

            new CBARDirectionalLight(context).load(undefined, {
                "intensity": 2.5,
                "color": "0xffffff",
                "position": [0,0,0]
            }).then((asset)=>{
                asset.light.castShadow = SHADOWS_ENABLED;
                asset.light.shadow.camera = new OrthographicCamera(-3,3,3,-3, 0.1, 1000);
                asset.light.shadow.mapSize = new THREE.Vector2(2048,2048);
                asset.light.target.position.set(3,10,-20);
                scene.assets.add(asset);
                setDirectionalLight(asset);
            });
        }

    }, [scene, context, loadImage]);

    const [sceneResolver, setSceneResolver] = useState<DeferredExecutor<CBARScene>>();

    const loadSceneData = useCallback((context:CBARContext, json:CBARSceneProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) => {

        return new Promise<CBARScene>((resolve, reject) => {
            if (!context) {
                reject(new Error("No context supplied"));
                return
            }
            new CBARScene(context).load(undefined, json, surfaceTypes, room, subroom).then(s=>{
                setScene(s);
                setSceneResolver({resolve, reject})
            }).catch(error=>{
                reject(error)
            })
        })

    }, []);

    const loadSceneAtPath = useCallback((context:CBARContext, dataPath:string, surfaceTypes?:CBARSurfaceType[]) => {

        let path = dataPath.split("/").slice(0,-1).join("/");

        return new Promise<CBARScene>((resolve, reject) => {
            if (!context) {
                reject(new Error("No context supplied"));
                return
            }
            fetch(dataPath).then((resp) => {
                resp.json().then((json: any) => {
                    new CBARScene(context).load(path, json, surfaceTypes).then(s=>{
                        setScene(s);
                        setSceneResolver({resolve, reject})
                    }).catch(error=>{
                        reject(error)
                    })
                }).catch((error)=>{
                    reject(new Error(`Invalid json data or path: ${dataPath}\n${error.message}`))
                })
            })
        })

    }, []);

    const onContextCreated = props.onContextCreated;
    const mediaReady = useCallback((view:CBARMediaView) => {

        if (context) {
            media.current = view;
            onContextCreated(context)
        }

    }, [context, media, onContextCreated]);

    const mediaPropertiesChanged = useCallback((view:CBARMediaView, props:CBARMediaInfo) => {
        if (scene) {
            scene.cameraProperties.setMediaProperties(props)
        }
        if (container.current) {
            container.current.style.visibility = "hidden";
            setCrop(undefined);
        }
        setMediaProperties(props);
    }, [scene, container]);

    useEffect(() => {
        if (sceneResolver && scene && canvas.current) {
            //finish any setup:
            scene.sceneLoaded()
            sceneResolver.resolve(scene);
            setSceneResolver(undefined)
            tick(canvas.current);
        }
    }, [sceneResolver, scene, tick, canvas]);

    const getMode = useCallback(() => {
        if (media.current) {
            return media.current.mode
        }
        return CBARMode.None
    }, [media]);

    //TODO:dependencies broken somehow, explaining weirdness here
    const _toolMode = useRef(CBARToolMode.None);
    useEffect(()=>{
        _toolMode.current = toolMode;
    }, [toolMode, _toolMode]);

    const getToolMode = useCallback(() => {
        return _toolMode.current;
    }, [_toolMode]);

    const makeId = useCallback((event: THREE.Intersection) => {
        return event.object.uuid + '/' + event.index
    }, []);

    const onDeviceEvent = useCallback((e:React.MouseEvent|React.TouchEvent, eventType:CBAREventType, position:THREE.Vector2) => {

        if (scene && context) {
            const rect = (e.target as HTMLElement).getBoundingClientRect();
            const { left, right, top, bottom } = rect;

            if (drawingOverlay.current) {
                const draw = isDrawingTool(toolMode);

                if (draw) {
                    const radius = drawingOverlay.current.getBoundingClientRect().width / 2.0;
                    drawingOverlay.current.style.left = `${position.x - radius}px`;
                    drawingOverlay.current.style.top = `${position.y - radius}px`;
                }

                if (container.current) {
                    container.current.style.cursor = draw ? "none" : "default";
                }

                drawingOverlay.current.style.visibility = isTouchDevice || !draw || eventType === CBAREventType.TouchLeave ? "hidden" : "visible";
            }

            const normalizedPoint = new THREE.Vector2((position.x - left) / (right - left), (position.y - top) / (bottom - top));
            const searchObjects = scene.getEventObjects(eventType);

            const intersections = raycast(normalizedPoint, searchObjects);

            const event:CBARMouseEvent = {
                type:eventType,
                context:context,
                point:normalizedPoint,
                toolMode:toolMode,
                scene,
                mouseEvent:e,
                intersections:intersections
            };
            scene.handleEvent(event);
        }
    }, [scene, context, raycast, makeId, toolMode, container, drawingOverlay, container]);

    const onMouseEvent = useCallback((e:React.MouseEvent, eventType:CBAREventType) => {
        onDeviceEvent(e, eventType, new THREE.Vector2(e.clientX, e.clientY));
    }, [onDeviceEvent])

    const lastPosition = useRef<THREE.Vector2>();
    const onTouchEvent = useCallback((e:React.TouchEvent, eventType:CBAREventType) => {
        const pos = lastPosition.current;
        if (e.touches.length === 1) {
            const position = new THREE.Vector2(e.touches[0].clientX, e.touches[0].clientY);
            onDeviceEvent(e, eventType, position);
            lastPosition.current = position;
        } else if (pos) {
            onDeviceEvent(e, eventType, pos);
            if (eventType === CBAREventType.TouchUp || eventType === CBAREventType.TouchLeave) {
                lastPosition.current = undefined;
            }
        }
    }, [onDeviceEvent, lastPosition])

    const initialize = useCallback(() => {
        if (!container.current || !canvas.current) return;

        const renderer = new THREE.WebGLRenderer({canvas:canvas.current});
        renderer.autoClearColor = false;
        renderer.sortObjects = false;
        renderer.autoClear = false;

        renderer.pixelRatio = window.devicePixelRatio;

        const width = container.current.clientWidth * renderer.pixelRatio;
        const height = container.current.clientHeight * renderer.pixelRatio;

        renderer.physicallyCorrectLights = true;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.shadowMap.autoUpdate = true;

        canvas.current.style.width = "100%";
        canvas.current.style.height = "100%";

        //depth buffer
        const target = new THREE.WebGLRenderTarget(width, height);
        target.texture.format = THREE.RGBFormat;
        //target.texture.minFilter = THREE.NearestFilter;
        //target.texture.magFilter = THREE.NearestFilter;
        //target.texture.generateMipmaps = false;

        target.stencilBuffer = false;
        target.depthBuffer = false;
        target.depthTexture = new THREE.DepthTexture(width, height);
        target.depthTexture.format = THREE.DepthFormat;
        target.depthTexture.type = THREE.UnsignedShortType;

        renderer.setSize(container.current.clientWidth, container.current.clientHeight);

        window.setInterval(()=>{
            doRender.current = true;
        }, 2000);

        setRenderContext(new CBARRenderContext(camera, sceneJS, renderer, target, resolution.current))

    }, [container, canvas, camera, sceneJS, doRender]);

    const initializeRef = useRef(initialize);
    useEffect(() => {
        initializeRef.current = initialize;
    }, [initialize]);

    useEffect(() => {
        if (initializeRef.current) {
            initializeRef.current()
        }
    }, [initializeRef]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            media.current = undefined;
        }
    }, []);

    const refScene = useRef<CBARScene>();

    const getScene = useCallback(()=>{
        return scene ? scene : refScene.current;
    }, [scene, refScene])

    useEffect(()=>{
        refScene.current = scene;
    },[scene])

    useMemo(()=>{
        if (!receiver) {
            setReceiver({loadSceneAtPath, loadSceneData, startVideoCamera, stopVideoCamera, captureImage, captureScreenshot, loadImage, refresh, getMode, getToolMode, getScene})
        }
    }, [receiver, loadSceneAtPath, loadSceneData, startVideoCamera, captureImage, captureScreenshot, loadImage, refresh, getMode, getToolMode, getScene])

    const touchEvents = useMemo(()=>{
        return isTouchDevice ? {
            onTouchStart:(e:React.TouchEvent<HTMLDivElement>)=>onTouchEvent(e, CBAREventType.TouchDown),
            onTouchEnd:(e:React.TouchEvent<HTMLDivElement>)=>onTouchEvent(e, CBAREventType.TouchUp),
            onTouchMove:(e:React.TouchEvent<HTMLDivElement>)=>onTouchEvent(e, CBAREventType.TouchMove),
            onTouchCancel:(e:React.TouchEvent<HTMLDivElement>)=>onTouchEvent(e, CBAREventType.TouchLeave)
        } : {
            onContextMenu:(e:React.MouseEvent<HTMLDivElement>)=>onMouseEvent(e, CBAREventType.ContextMenu),
            onWheel:(e:React.MouseEvent<HTMLDivElement>)=>onMouseEvent(e, CBAREventType.Wheel),
            onPointerDown:(e:React.MouseEvent<HTMLDivElement>)=>onMouseEvent(e, CBAREventType.TouchDown),
            onPointerUp:(e:React.MouseEvent<HTMLDivElement>)=>onMouseEvent(e, CBAREventType.TouchUp),
            onPointerLeave:(e:React.MouseEvent<HTMLDivElement>)=>onMouseEvent(e, CBAREventType.TouchLeave),
            onPointerMove:(e:React.MouseEvent<HTMLDivElement>)=>onMouseEvent(e, CBAREventType.TouchMove)
        }
    }, [onTouchEvent])

    return (
        <div ref={primaryContainer} className={props.className} style={{width:"100%", height:"100%", position:"relative"}}>
            <div ref={drawingOverlay} style={{visibility:"hidden", pointerEvents: "none", userSelect:"none",
                position: "fixed", zIndex:1, top:0, left:0, height: "25px", width:"25px",
                backgroundColor: "#ffa", borderRadius:"50%", display:"inline-block"}} />

            <div ref={container} className={"cbar-zoomable"} style={{width:"100%", height:"100%", position:"absolute"}} {...touchEvents}>
                <canvas ref={canvas} />
            </div>

            {context && <CBARMediaView
                context={context}
                onMediaReady={mediaReady}
                onMediaUpdated={mediaPropertiesChanged} />}

            <WebClientInfo onClientStateChanged={setBrowserProperties} />
        </div>
    )
}