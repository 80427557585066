import {CBARCollection} from "../CBARCollection";
import {CBARObject3D, CBARObject3DProperties} from "./CBARObject3D";
import {
    CBAREvent,
    CBAREventType,
    CBARHighlightState,
    CBARHistoryState,
    CBARMouseEvent,
    CBARSurfaceType,
    CBARToolMode
} from "../CBARTypes";
import {CBARImageCollection, CBARImageDictionary} from "./CBARImageCollection";
import {CBARSurfaceAsset} from "../assets/CBARSurfaceAsset";
import {CBARContext, isDrawingTool} from "../CBARContext";

import {CBARMaterialProperty, CBARTextureType} from "./CBARMaterial";

import * as THREE from "three";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial";
import {Line2} from "three/examples/jsm/lines/Line2";
import {getConfig} from "../../backend";
import {CBARImage} from "./CBARImage";
import {normalizedToScreen} from "../internal/Utils";
import * as internal from "../internal/Internal"
import {CBARSuperpixelsImage} from "./CBARSuperpixelsImage";
import {CBARMaskTexture} from "./CBARMaskTexture";
import {CBARStandardMaterial} from "./CBARStandardMaterial";

const colors = ['aqua', 'blue', 'red', 'green', 'lime', 'maroon', 'navy', 'olive',
    'purple', 'fuchsia', 'silver', 'teal', 'white', 'yellow', 'orange'];
let colorIndex = 0;

export interface CBARSurfaceProperties extends CBARObject3DProperties {
    type?:CBARSurfaceType
    color?:string
    extent?:number[],
    normal?:number[],
    offset?:number,
    images:CBARImageDictionary,
    contours?:any[]
}

const APPROX_POLY = true;
const POLY_EPSILON = 1.0;
const MAX_PROJECTED_DISTANCE = 100.0;
const MIN_CONTOUR_AREA = 20 * 20;

let selectedSurface:CBARSurface|undefined = undefined;

export class CBARSurface extends CBARObject3D<CBARSurface> implements CBARCollection<CBARSurfaceAsset> {

    _generatedColor = new THREE.Color(colors[(colorIndex ++) % colors.length]);

    public axisRotation = 0.0;

    public constructor(context:CBARContext) {
        super(context);

        this.material = new THREE.MeshLambertMaterial({color:this._generatedColor, transparent: true, opacity: Math.min(this.baseOpacity, getConfig().hoverBGOpacity), depthTest:false});
        this.setRenderObject(this._container);
    }

    private get hasPlaceholder() {
        return this.type === CBARSurfaceType.Floor && !!this.context.scene?.placeholderTexture && this.length() === 0;
    }

    public needsUpdate() {

        this._placeholderMaterial?.setMaterialProperty(CBARMaterialProperty.opacity, this.hasPlaceholder ? 1.0 : 0.0001);

        // const config = getConfig();
        // let opacity = 0.0;
        // if (this.selected && !this.length() && !this.context.isDrawing) {
        //     opacity = Math.max(opacity, config.selectedOutlineOpacity);
        // }
        // if (this.getState(CBARHighlightState.Hover).active) {
        //     opacity = Math.max(opacity, config.hoverOutlineOpacity);
        // }
        //
        // for (const outline of this._outlines) {
        //     const material = outline.material as THREE.Material;
        //     if (!material) break;
        //     outline.visible = opacity > 0.0
        // }
        //
        // if (this.selected && this.context.isDrawing) {
        //     this.material.opacity = this.length() ? this.baseOpacity : 0.4;
        // }
        //
        // if (this.getState(CBARHighlightState.Hover).active) {
        //     this.material.opacity = Math.max(this.material.opacity, config.hoverBGOpacity);
        // }
    }

    private _container = new THREE.Group();
    public baseOpacity = 0.0;
    public material:THREE.MeshLambertMaterial;

    protected images = new CBARImageCollection(this.context);

    private _assets: { [id: string]: CBARSurfaceAsset} = {};

    public get selectedColor() {
        return this.color
    }

    public get values() : { [id: string] : CBARSurfaceAsset; } {
        return this._assets
    }

    public first() {
        const length = this.length();
        if (length) {
            return this.all()[0];
        }
    }

    public last() {
        const length = this.length();
        if (length) {
            return this.all()[length-1];
        }
    }

    public all() {
        return Object.values(this._assets)
    }

    public get sorted() : CBARSurfaceAsset[] {
        return Object.values(this._assets).sort((a, b) => a.surfaceElevation - b.surfaceElevation)
    }

    private sortAssets() {
        const assets = this.sorted;//why is this changing?
        assets.forEach(asset=>{
            this.renderObject.remove(asset.renderObject);
        });
        assets.forEach(asset=>{
            this.renderObject.add(asset.renderObject);
        });

        this.needsUpdate();
    }

    public add(asset:CBARSurfaceAsset, elevation=0.0) {

        asset.surface = this;

        if (this.type === CBARSurfaceType.Floor) {
            //place in front of us on the floor, otherwise (default) the center of the surface
            const maskCenter = new THREE.Vector2(0.5,0.7);//hard coded center of floor in front and down.
            const center = this.screenToSurfacePosition(maskCenter);
            asset.setSurfacePosition(center.x, center.y);
        }

        asset.surfaceElevation = elevation;
        this._assets[asset.id] = asset;

        this.context.scene?.assets.add(asset);
        asset.addedToSurface(this);

        this.sortAssets();
    }

    public containsKey(key:string) : boolean {
        return this._assets.hasOwnProperty(key);
    }

    public remove(asset:CBARSurfaceAsset) {
        if (!this.containsKey(asset.id) || asset.surface !== this) return;
        this.context.scene?.assets.remove(asset);
        this.needsUpdate();
    }

    //internal: called from CBARSurfaceAsset.removeFromScene()
    private internal_removeKey(key:string) {
        delete this._assets[key]
        this.needsUpdate();
    }

    public length(): number {
        return Object.keys(this._assets).length
    }

    private _extent = new THREE.Vector2();

    public get extent() : THREE.Vector2 {
        return this._extent
    }

    public type = CBARSurfaceType.Unknown;

    private _planeNormal?:THREE.Vector3;
    public get planeNormal() {
        return this._planeNormal
    }

    private _planeOffset?: number;
    public get planeOffset() {
        return this._planeOffset
    }

    private _raycaster = new THREE.Raycaster();

    public get color() {
        return this.material.color
    }

    public set color(value) {
        this.material.color = value
    }

    public maskTexture?:CBARMaskTexture;

    public plane = new THREE.Plane();

    private _outlines:Line2[] = [];
    private _shapeMeshes:THREE.Mesh[] = [];
    private _placeholderMeshes:THREE.Mesh[] = [];
    private _placeholderMaterial?:CBARStandardMaterial;

    load(basePath:string|undefined, json:CBARSurfaceProperties, room?:string|null|undefined, subroom?:string|null|undefined) : Promise<CBARSurface> {

        if (json.type ) {
            this.type = json.type
        }

        if (json.color) {
            this.color = new THREE.Color(parseInt(json.color, 16))
        }

        if (json.normal) {
            this._planeNormal = new THREE.Vector3(-json.normal[0], -json.normal[2], json.normal[1])
        }

        this._planeOffset = json.offset ? json.offset : Number.EPSILON;

        const promises:any[] = [];

        promises.push(this.images.load(basePath, json.images, room, subroom));

        //const maskSize:Size = {width:640, height:480};

        return new Promise<CBARSurface>((resolve, reject) => {
            super.load(basePath, json).then(()=>{
                Promise.all(promises).then(()=>{

                    if (this._planeOffset && this._planeNormal) {

                        this.plane = new THREE.Plane(this._planeNormal, this._planeOffset);
                        this.extent.x = 0.0;
                        this.extent.y = 0.0;
                    }

                    if (this.maskImage && this.maskImage.area) {
                        this.maskTexture = new CBARMaskTexture(this.context, CBARTextureType.alpha);
                        this.maskTexture.loadImage(this.maskImage);
                    }

                    resolve(this)
                }).catch(error=>{
                    reject(error)
                })
            }).catch(error=>{
                reject(error)
            })
        })
    }

    public get maskImage() : CBARImage | undefined {
        if (this.images.containsKey("mask")) {
            return this.images.values['mask'] as CBARImage;
        }
    }

    get description() : string {
        return `${this.type} Surface`
    }

    public data() : any {
        const data:any = super.data();

        data.extent = [this.extent.x, this.extent.y];

        data.color = this.color.getHexString();

        return data
    }

    private getSuperpixelsImage() : CBARSuperpixelsImage | undefined {
        return (<internal.CBARScene><any>this.context.scene)?.superpixelsImage;
    }

    private _drawingInterval = 0;
    private _drawingPoints:THREE.Vector2[] = [];

    private drawingMaybeChanged(toolMode:CBARToolMode) {

        if (!this.maskTexture) {
            console.log("No mask texture");
            return;
        }

        if (this.maskTexture.isEditing) {
            if  (!this.isDrawingOn || !this.selected || !isDrawingTool(toolMode)) {
                this.maskTexture.isEditing = false;
                this.maskTexture.applyBlend();
                this.clearHistory();
                this.regenerateMeshes(true);
                this.context.refresh();
                this.needsUpdate();

                if (this._drawingInterval) {
                    window.clearInterval(this._drawingInterval);
                    this._drawingInterval = 0;
                }

                //console.log("stopped editing");
            }
        } else {
            if (this.selected && this.isDrawingOn) {
                this.maskTexture.isEditing = true;
                this.context.refresh();

                if (!this._drawingInterval) {
                    this._drawingInterval = window.setInterval(()=>{
                        this.updateDrawing()
                    }, window.outerWidth > 500 ? 250 : 1000);
                }

                //console.log("started editing");
            }
        }
    }

    private drawEraseAtPoint(event: CBARMouseEvent) {
        this.drawingMaybeChanged(this.context.toolMode);
        this._drawingPoints.push(event.point);
    }



    private updateDrawing() {
        //thread lock would be nice here:
        if (!this._drawingPoints.length || !this.context.scene?.cvBackground) {
            return;
        }

        const drawQueue = this._drawingPoints.map((p)=>p);
        this._drawingPoints.length = 0;

        if (!this.maskTexture || !this.maskTexture.canvas) {
            console.log("no mask texture");
            return;
        }

        const radius = this.context.scene.cvBackground.rows / 30;
        const color = this.context.toolMode === CBARToolMode.DrawSurface ? [255,255,255,255] : [0,0,0,0];
        const markers = cv.Mat.zeros(this.context.scene.cvBackground.rows, this.context.scene.cvBackground.cols, cv.CV_32S);
        markers.setTo([127, 127, 127, 127]);

        drawQueue.forEach((p)=>{
            const cvPoint = new cv.Point(p.x * markers.cols, p.y * markers.rows);
            cv.circle(markers, cvPoint, radius, [0,0,0,0], cv.FILLED);
            cv.circle(markers, cvPoint, radius / 3, [255,255,255,255], cv.FILLED);
        });

        // this._sceneBackgroud.roi()

        //watershed
        cv.watershed(this.context.scene.cvBackground, markers);
        markers.convertTo(markers, cv.CV_8U, 1, 0);
        cv.threshold(markers, markers, 254, 255, cv.THRESH_BINARY);

        //commit changes
        const mask = cv.imread(this.maskTexture.canvas);
        //add to mask
        cv.resize(markers, markers, new cv.Size(mask.cols,mask.rows));
        mask.setTo(color, markers);
        markers.delete();

        cv.imshow(this.maskTexture.canvas, mask);
        mask.delete();

        this.maskTexture.refresh();
        this.context.refresh();
    }

    protected undoHistory:CBARHistoryState[] = [];

    protected saveState() {
        if (this.maskTexture && this.maskTexture.canvas) {
            this.undoHistory.push(new CBARHistoryState(this.maskTexture.canvas));
        }
    }

    protected restoreState(state:CBARHistoryState, completed?:(success:boolean)=>void) {
        if (this.maskTexture && this.maskTexture.canvas) {
            const tex = this.maskTexture;
            state.restoreInto(this.maskTexture.canvas, ()=>{
                this.regenerateMeshes(false);
                tex.refresh();
                this.context.refresh();
                if (completed) completed(true);
            });
        } else if (completed) {
            completed(false);
        }
    }

    public get historyLength() {
        return this.undoHistory.length;
    }

    public undoLast(completed?:(success:boolean)=>void) {
        const state = this.undoHistory.pop();
        if (state) {
            this.restoreState(state, completed);
        } else {
            console.warn("Nothing to undo");
            if (completed) completed(false);
        }
    }

    public clearHistory() {
        this.undoHistory = [];
    }

    public revertChanges(completed?:(success:boolean)=>void) {
        if (this.undoHistory.length) {
            this.restoreState(this.undoHistory[0], (success:boolean)=>{
                this.clearHistory();
                if (completed) completed(success);
            });
        } else {
            //console.warn("Nothing to revert");
            if (completed) completed(false);
        }
    }

    public commitChanges(completed?:(success:boolean)=>void) {
        if (this.historyLength) {
            this.clearHistory();
            if (completed) completed(true);
        } else {
            console.warn("Nothing to commit");
            if (completed) completed(false);
        }
    }

    public get selected() {
        if (this.context.scene) {
            return this.context.scene.surfaceFor(CBARHighlightState.Selected) === this;
        }
        return false;
    }

    public set selected(value:boolean) {
        if (this.context.scene) {
            if (value) {
                this.context.scene.setSurfaceFor(CBARHighlightState.Selected, this);
            } else if (this.selected) {
                this.context.scene.setSurfaceFor(CBARHighlightState.Selected, undefined);
            }
        } else {
            console.log("selected: No scene");
        }
    }

    public get isDrawingOn() {
        return isDrawingTool(this.context.toolMode) && this.selected
    }

    public handleEvent(event: CBAREvent) {
        super.handleEvent(event);

        switch (event.type) {

            case CBAREventType.TouchDown:
                if (!this.selected) {
                    if (!this.context.scene) {
                        console.log("No scene!");
                        return;
                    }
                    this.context.scene.setSurfaceFor(CBARHighlightState.Selected, this, event);
                }
                if (this.isDrawingOn) {
                    this.saveState();
                    this.drawEraseAtPoint(event as CBARMouseEvent);
                }
                break;

            case CBAREventType.TouchUp:
                if (this.isDrawingOn) {
                    this.drawEraseAtPoint(event as CBARMouseEvent);
                }
                break;

            case CBAREventType.DragMove:
                if (this.isDrawingOn) {
                    this.drawEraseAtPoint(event as CBARMouseEvent);
                }
                break;
        }
    }

    public clearAll() {
        const assets = this.values;

        for (let key in assets) {
            this.remove(assets[key])
        }
    }

    public existsAtPoint(coords:THREE.Vector2) : boolean {
        if (!this.maskTexture || !this.maskTexture.canvas) return false;

        if (isDrawingTool(this.context.toolMode)) {
            return true;
        }

        const ctx = this.maskTexture.canvas.getContext("2d");

        if (!ctx) return false;

        const xPos = coords.x * this.maskTexture.canvas.width;
        const yPos = coords.y * this.maskTexture.canvas.height;

        const pixel = ctx.getImageData(xPos, yPos, 1, 1);

        return pixel.data.length > 0 && pixel.data[0] > 128;
    }

    protected getPlaneIntersection(point2D: THREE.Vector2) {
        const screenPoint = normalizedToScreen(point2D);
        this._raycaster.setFromCamera(screenPoint, this.context.gl.camera);
        return this._raycaster.ray.intersectPlane(this.plane, new THREE.Vector3());
    }

    public screenToSurfacePosition(point2D:THREE.Vector2) : THREE.Vector2 {
        const point3D = this.getPlaneIntersection(point2D);
        return point3D ? this.getSurfacePosition(point3D) : new THREE.Vector2();
    }

    private _pointTransform = new THREE.Matrix4().identity();

    public getSurfacePosition(point3d:THREE.Vector3) : THREE.Vector2 {
        const point4D = new THREE.Vector4(point3d.x, point3d.y, point3d.z, 1.0).applyMatrix4(this._pointTransform);
        return new THREE.Vector2(point4D.x, point4D.y);
    }

    private removeMeshes() {

        this._outlines.forEach(m=>this._container.remove(m));
        this._outlines = [];

        this._shapeMeshes.forEach(m=>this._container.remove(m));
        this._shapeMeshes = [];

        this._placeholderMeshes.forEach(m=>this._container.remove(m));
        this._placeholderMeshes = [];
    }

    private findContours(maskImage:HTMLCanvasElement, calculateTransform:boolean) {
        const mask = cv.imread(maskImage);

        if (mask.channels() == 4) {
            cv.cvtColor(mask, mask, cv.COLOR_RGBA2GRAY, 0)
        } else if (mask.channels()==3) {
            cv.cvtColor(mask, mask, cv.COLOR_RGB2GRAY, 0)
        }

        const contours = new cv.MatVector();
        const hierarchy = new cv.Mat();
        cv.findContours(mask, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

        const size = mask.size();
        const scale = 200.0 / Math.max(size.width, size.height);
        let expSize = new cv.Size(scale * size.width, scale * size.height);
        if (scale < 1.0){
            cv.resize(mask, mask, expSize);
        } else {
            expSize = size;
        }
        const expandedContours = new cv.MatVector();
        cv.findContours(mask, expandedContours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
        mask.delete();

        //typedefs are wrong inside mirada:
        const length = (contours.size() as any) as number;

        const threeContours: THREE.Vector2[][] = [];
        const threeHulls: THREE.Vector2[][] = [];

        for (let i = 0; i < length; i++) {
            const contour = contours.get(i);

            let poly = new cv.Mat();
            if (APPROX_POLY) {
                cv.approxPolyDP(contour, poly, POLY_EPSILON, false)
            } else {
                poly = contour
            }

            const area = cv.contourArea(poly);
            if (area < MIN_CONTOUR_AREA) continue;

            const threeContour:THREE.Vector2[] = [];
            for (let j = 0; j < poly.data32S.length; j+=2) {
                const point = new THREE.Vector2(poly.data32S[j]/maskImage.width, poly.data32S[j+1]/maskImage.height);
                threeContour.push(point);
            }
            threeContours.push(threeContour);

            //find an enclosing contour for a decent clickable region (drawing, etc)
            const threeHull:THREE.Vector2[] = [];

            const expContour = expandedContours.get(i);

            if (expContour) {
                for (let j = 0; j < expContour.data32S.length; j+=2) {
                    const point = new THREE.Vector2(expContour.data32S[j]/expSize.width, expContour.data32S[j+1]/expSize.height);
                    threeHull.push(point);
                }
            } else {
                for (let j = 0; j < contour.data32S.length; j+=2) {
                    const point = new THREE.Vector2(contour.data32S[j]/maskImage.width, contour.data32S[j+1]/maskImage.height);
                    threeHull.push(point);
                }
            }

            threeHulls.push(threeHull);

            poly.delete();
        }

        contours.delete();
        expandedContours.delete();

        if (this.maskTexture) {
            this.maskTexture.refresh();
        }

        this.removeMeshes();

        const mx = new THREE.Matrix4().lookAt(this.plane.normal, new THREE.Vector3(), new THREE.Vector3(0,1,0));
        const _rotation = new THREE.Quaternion().setFromRotationMatrix(mx);

        const euler = new THREE.Euler().setFromQuaternion(_rotation);
        const rotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, this.axisRotation));

        this.castContours(threeContours, threeHulls, rotation, calculateTransform);

        this._container.rotation.setFromQuaternion(rotation);
    }

    private projectPointsOntoPlane(contours: THREE.Vector2[][], contours3D:THREE.Vector3[][]) {
        const totalPoint = new THREE.Vector3();
        let numPoints = 0;

        for (let i = 0; i < contours.length; i++) {
            const contour = contours[i];
            const points3D: THREE.Vector3[] = [];
            for (let j = 0; j < contour.length; j++) {

                const point3D = this.getPlaneIntersection(contour[j]);

                if (point3D && point3D.length() <= MAX_PROJECTED_DISTANCE) {
                    points3D.push(point3D);
                    totalPoint.x += point3D.x;
                    totalPoint.y += point3D.y;
                    totalPoint.z += point3D.z;
                    numPoints += 1
                }
            }

            if (points3D.length > 2) {
                points3D.push(points3D[0]);//join start and end
                contours3D.push(points3D);
            }
        }

        return new THREE.Vector3(
            totalPoint.x / numPoints,
            totalPoint.y / numPoints,
            totalPoint.z / numPoints);
    }

    private castContours(contours: THREE.Vector2[][], hulls: THREE.Vector2[][], rotation:THREE.Quaternion, calculateTransform:boolean) {

        const contours3D:THREE.Vector3[][] = [];
        const center3D = this.projectPointsOntoPlane(contours, contours3D);

        const hull3D:THREE.Vector3[][] = [];
        this.projectPointsOntoPlane(hulls, hull3D);

        if (calculateTransform) {
            this._pointTransform = new THREE.Matrix4().compose(center3D, rotation, new THREE.Vector3(1,1,1)).invert()
        }

        const min = new THREE.Vector2(Number.MAX_SAFE_INTEGER,Number.MAX_SAFE_INTEGER);
        const max = new THREE.Vector2(Number.MIN_SAFE_INTEGER,Number.MIN_SAFE_INTEGER);
        const contoursPlane = [];

        let index = 0;
        for (const contour of contours3D) {
            const translatedContour = [];
            for (const point3D of contour) {
                const point2D = this.getSurfacePosition(point3D);
                translatedContour.push(point2D);
                min.x = Math.min(min.x, point2D.x);
                min.y = Math.min(min.y, point2D.y);
                max.x = Math.max(max.x, point2D.x);
                max.y = Math.max(max.y, point2D.y);
            }
            translatedContour.push(translatedContour[0]); //close loop

            const hull = index < hull3D.length ? hull3D[index] : contour;
            const translatedHull = [];
            for (const point3D of hull) {
                translatedHull.push(this.getSurfacePosition(point3D));
            }
            translatedHull.push(translatedHull[0]); //close loop

            const shape = new THREE.Shape();
            shape.setFromPoints(translatedHull);
            contoursPlane.push(shape);

            //add outside lines
            const lineMaterial = new LineMaterial({
                vertexColors: false,
                transparent:true,
                opacity: 0.0,
                color:new THREE.Color("white").getHex(),
                linewidth: 2.0,
                blending:THREE.AdditiveBlending,
                resolution: new THREE.Vector2(this.context.gl.renderer.domElement.width * window.devicePixelRatio, this.context.gl.renderer.domElement.height * window.devicePixelRatio),
                dashed: false,
                depthTest:false
            });

            const positions = [];
            const colors = [];
            for (const point of translatedContour) {
                positions.push(point.x, point.y, 0);
                colors.push( this.color.r, this.color.g, this.color.b )
            }

            const lineGeometry = new LineGeometry();
            lineGeometry.setPositions(positions);
            lineGeometry.setColors(colors);

            const line = new Line2(lineGeometry, lineMaterial );
            line.computeLineDistances();
            line.scale.set( 1, 1, 1 );
            line.visible = false;
            line.renderOrder = 10000;

            this._outlines.push(line);
            this._container.add(line);

            index += 1;
        }

        //add each shape
        for (const shape of contoursPlane) {
            //add shape
            const geometry = new THREE.ShapeBufferGeometry(shape);
            const mesh = new THREE.Mesh(geometry, this.material);
            mesh.renderOrder = 10000;
            this._container.add(mesh);
            this._shapeMeshes.push(mesh);

            //const shadowMaterial = new THREE.ShadowMaterial({opacity:0.01});
            if (this._placeholderMaterial) {
                const placeholderShape = new THREE.Shape();
                const halfWidth = 50; //infinite-ish
                placeholderShape.moveTo( -halfWidth, -halfWidth);
                placeholderShape.lineTo( halfWidth, -halfWidth);
                placeholderShape.lineTo( halfWidth, halfWidth);
                placeholderShape.lineTo( -halfWidth, halfWidth);
                placeholderShape.lineTo( -halfWidth, -halfWidth);

                const placeholderGeometry = new THREE.ShapeBufferGeometry(placeholderShape);
                const placeholderMesh = new THREE.Mesh(placeholderGeometry, this._placeholderMaterial.threeMaterial);

                if (this.context.scene) {
                    //placeholderMesh.rotateZ(this.context.scene.groundRotation);
                    //console.log("rotation", this.context.scene.groundRotation)
                }

                placeholderMesh.receiveShadow = this.length() > 0;
                placeholderMesh.castShadow = false;
                this._container.add(placeholderMesh);
                this._placeholderMeshes.push(placeholderMesh);
            }
        }

        //calc extents
        const xSpan = 2 * Math.max(max.x - min.x, 2);
        const ySpan = 2 * Math.max(max.y - min.y, 2);

        this._extent = new THREE.Vector2(xSpan, ySpan);
        if (calculateTransform) {
            this._container.position.set(center3D.x, center3D.y, center3D.z);
        }
    }

    private regenerateMeshes(calculateTransform:boolean) {
        if (this.maskTexture && this.maskTexture.canvas && this.context.scene?.placeholderTexture && this.context.scene?.lightingTexture) {
            this._placeholderMaterial = new CBARStandardMaterial(this.context);
            this._placeholderMaterial.threeMaterial.map = this.context.scene.placeholderTexture.threeTexture;
            this._placeholderMaterial.repeat = new THREE.Vector2(1,1);
            this._placeholderMaterial.threeMaterial.alphaMap = this.maskTexture.threeTexture;
            this._placeholderMaterial.threeMaterial.lightMap = this.context.scene.lightingTexture.threeTexture;

            this.findContours(this.maskTexture.canvas, calculateTransform);
        }
        this.sortAssets();
    }

    removeFromScene() : void {
        if (selectedSurface === this) {
            selectedSurface = undefined
        }
        this.removeMeshes();
        super.removeFromScene();
    }

    sceneLoaded() {
        this.axisRotation = this.context.scene!.groundRotation;
        this.regenerateMeshes(true);

        if (this.maskTexture) {
            if (this.getSuperpixelsImage()) {
                this.maskTexture.applyBlend();
            } else {
                this.maskTexture.blendResults = false;
            }
        }

        this.needsUpdate();
    }

    public get receivesEvents() : boolean {
        return true
    }

    public toolModeChanged(toolMode:CBARToolMode) {
        super.toolModeChanged(toolMode)
        this.drawingMaybeChanged(toolMode);
    }
}

