import {Mat} from "mirada";
import * as gm from "gammacv";
import {CBARPipeline} from "./pipeline/CBARPipeline";

export class CBARFrame {
    constructor(public readonly context:CBARPipeline, public readonly index:number, public readonly time = performance.now()) {

    }

    get greyscale() {
        if (!this._hasGreyscale && this.context.session && this.context.greyscaleOp && this.context.greyscaleOutput) {
            this._hasGreyscale = true;
            this.context.session.runOp(this.context.greyscaleOp, this.index, this.context.greyscaleOutput);
        }
        return this.context.greyscaleOutput;
    }; private _hasGreyscale = false;

    get greyscaleImage() {
        if (!this._greyscaleImage && this.greyscale) {
            gm.canvasFromTensor(this.context.greyscaleCanvas, this.greyscale);
            this._greyscaleImage = cv.imread(this.context.greyscaleCanvas);
            cv.cvtColor(this._greyscaleImage, this._greyscaleImage, cv.COLOR_RGBA2GRAY, 0);
        }

        return this._greyscaleImage!;
    }; private _greyscaleImage?:Mat;

    get edges() {
        if (!this._hasEdges && this.context.session && this.context.edgesOp && this.context.edgesOutput) {
            this._hasEdges = true;
            this.context.session.runOp(this.context.edgesOp, this.index, this.context.edgesOutput);
        }
        return this.context.edgesOutput;
    }; private _hasEdges = false;

    get output() {
        if (!this._hasOutput && this.context.session && this.context.pipeline && this.context.output) {
            this._hasOutput = true;
            this.context.session.runOp(this.context.pipeline as gm.Operation, this.index, this.context.output);
        }
        return this.context.output;
    }; private _hasOutput = false;

    public destroy() {
        this._greyscaleImage?.delete();
        this._greyscaleImage = undefined;
    }

    public clone() {
        const copy = new CBARFrame(this.context, this.index, this.time);
        copy._greyscaleImage = this._greyscaleImage?.clone()
        return copy;
    }
}