import {CBARObject} from "./CBARObject";
import * as THREE from "three";
import {CBARTexture} from "./CBARTexture";
import {Texture} from "three";
import {CBARError, CBARErrorCode} from "../CBARError";
import {CBARCollection} from "../CBARCollection";
import {parseIncludes} from "../internal/Utils";
import {CBARContext} from "../CBARContext";
import {ImageCrop} from "cambrian-base";

export enum CBARTextureType {
    albedo = 'albedo',
    normals = 'normals',
    bump = 'bump',
    roughness = 'roughness',

    lightMap = 'lightMap',
    alpha = 'alpha',
    emissive = 'emissive',
    displacement = 'displacement',
    metalness = 'metalness',
    environment = 'environment'
}

export enum CBARMaterialProperty {
    color = 'color',
    opacity = 'opacity',

    roughnessValue = 'roughnessValue',
    metalnessValue = 'metalnessValue',
    bumpScaleValue = 'bumpScaleValue',

    lightMapIntensity = 'lightMapIntensity',
    aoMapIntensity =  'aoMapIntensity',
    emissiveIntensity = 'emissiveIntensity',
    displacementScale = 'displacementScale',
    displacementBias = 'displacementBias',
    envMapIntensity =  'envMapIntensity',
    refractionRatio = 'refractionRatio',
}

export interface CBARMaterialProperties {
    ppi?:number,
    ppcm?:number,
    scale?:number,
    mirrored?:boolean,
    mirroredX?:boolean,
    mirroredY?:boolean,
    repeat?:boolean,
    crop?: ImageCrop
    textures?: Record<string, string>
    properties?: Record<string, any>

    lightingFactor?:number
    smoothstep?:number
    lightingOffset?:number
}

export abstract class CBARMaterial<T> extends CBARObject<CBARMaterial<T>> implements CBARCollection<CBARTexture> {

    protected constructor(context:CBARContext, reference?: CBARMaterial<T>) {
        super(context);

        if (reference) {
            Object.assign(this, reference)
        }
    }

    protected abstract cloneObject() : CBARMaterial<T>

    protected _material?:THREE.Material;

    public wrappingX = THREE.RepeatWrapping;
    public wrappingY = THREE.RepeatWrapping;

    public abstract get threeMaterial() : THREE.Material

    public setThreeMaterial(value:THREE.Material) {
        this._material = value;
        this._material.onBeforeCompile = (shader) => {
            this.onBeforeCompileCallback(shader)
        }
    }

    private _uniform?:THREE.IUniform

    protected get isPaint() {
        return false;
    };

    protected onBeforeCompileCallback(shader:THREE.Shader) {
        shader.fragmentShader = parseIncludes(shader.fragmentShader);

        //add some functions:
        shader.fragmentShader = shader.fragmentShader.replace(/#if NUM_DIR_LIGHTS > 0/gi,`
                    vec3 rgb2hsv(in vec3 c) {
                        vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
                        vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
                        vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
                        
                        float d = q.x - min(q.w, q.y);
                        float e = 1.0e-10;
                        return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
                    }
                    
                    vec3 hsv2rgb(in vec3 c) {
                        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
                        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
                        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
                    }
                    
                    #if NUM_DIR_LIGHTS > 0`)

        const uniformName = "u_viewportSize";

        this._uniform = shader.uniforms[uniformName] = {value: this.context.gl.resolution};

        const prepend = `uniform vec2 ${uniformName};\n`;
        shader.fragmentShader = prepend + shader.fragmentShader;

        //TODO: vUv * vUv2 * vec2(0.0) disables warnings:
        // gl.getProgramInfoLog() WARNING: Output of vertex shader 'vUv' not read by fragment shader etc.
        // so find the correct way

        const replacement = `vUv * vUv2 * vec2(0.0) + gl_FragCoord.xy / ${uniformName}`;

        const hasAlpha = (this.threeMaterial as any).hasOwnProperty("alphaMap");
        //make mask use screen uv coordinates
        if (hasAlpha)
        {
            const textureName = 'alphaMap';
            shader.fragmentShader = shader.fragmentShader.replace(
                new RegExp(`texture2D\\( *${textureName}, *vUv *\\)`),
                `texture2D( ${textureName}, ${replacement})`)
        }

        //make lightmap use screen uv coordinates
        const hasLightMap = (this.threeMaterial as any).hasOwnProperty("lightMap");
        if (hasLightMap)
        {
            const textureName = 'lightMap';
            shader.fragmentShader = shader.fragmentShader.replace(
                new RegExp(`texture2D\\( *${textureName}, *vUv2 *\\)`),
                `texture2D( ${textureName}, ${replacement})`)
        }

        //make metalness map use screen uv coordinates
        const hasMetalness = (this.threeMaterial as any).hasOwnProperty("metalnessMap");
        if (hasMetalness)
        {
            const textureName = 'metalnessMap';
            shader.fragmentShader = shader.fragmentShader.replace(
                new RegExp(`texture2D\\( *${textureName}, *vUv *\\)`),
                `texture2D( ${textureName}, ${replacement})`);
        }

        //ignore lightmap at irradiance

        if (hasLightMap) {
            if (this.isPaint) {
                shader.fragmentShader = shader.fragmentShader.replace(/#endif\s+#ifdef DITHERING/gi,`
                    #endif
                    
                    vec3 colorHSV = rgb2hsv(gl_FragColor.rgb);
                    colorHSV.b = ${this.lightingOffset.toFixed(2)} + lightMapIrradiance.g * ${this.lightingFactor.toFixed(2)};
                    gl_FragColor.rgb = hsv2rgb(colorHSV);
                    
                    #ifdef DITHERING`)
            } else {
                shader.fragmentShader = shader.fragmentShader.replace(
                    'irradiance += lightMapIrradiance','');

                //add screen coordinate based lightmap at very end of shader:
                shader.fragmentShader = shader.fragmentShader.replace(/#endif\s+#ifdef DITHERING/gi,`
                    #endif
                    
                    float lightingOffset = ${this.lightingOffset.toFixed(2)};
                    vec3 lightingColor = ${this.lightingFactor.toFixed(2)} * (lightingOffset + (1.0 - lightingOffset) * lightMapIrradiance) - 0.5;
                    gl_FragColor.rgb = smoothstep(-0.0, ${this.smoothstep.toFixed(2)}, gl_FragColor.rgb + lightingColor);
                    
                    #ifdef DITHERING`)
            }
        }

        //console.log(`Shader hasAlpha:${hasAlpha}, hasLightMap:${hasLightMap}, hasMetalness:${hasMetalness}`)
        //console.log("fragment shader", shader.fragmentShader);
    }

    protected setTextureMap(texture:CBARTexture) : Texture | null {
        return null
    }

    private _materialProperties: { [property: string] : number; } = {};

    public setMaterialProperty(name:CBARMaterialProperty, value:any) {
        this._materialProperties[name] = value
    }

    public getMaterialProperty(name:CBARMaterialProperty) : any {
        return this._materialProperties[name]
    }

    public lightingFactor = 1.0;
    public smoothstep = 1.4;
    public lightingOffset = 0.3;

    public ppcm = 20 / 2.54;
    public scale = 1.0;

    public get ppi() : number {
        return this.ppcm * 2.54
    }

    public set ppi(value:number) {
        this.ppcm = value / 2.54
    }

    public get pixelsPerMeter() : number {
        return this.ppcm * 100.0 / this.scale;
    }

    public get imageDimensions() : THREE.Vector2 | undefined {
        const albedo = this.values[CBARTextureType.albedo];
        if (albedo) {
            return albedo.imageDimensions
        }
        return undefined
    }

    public get physicalDimensions() : THREE.Vector2 | undefined {
        if (this.imageDimensions) {
            return new THREE.Vector2( this.imageDimensions.x / this.pixelsPerMeter, this.imageDimensions.y / this.pixelsPerMeter)
        }
        return undefined
    }

    public get mirroredX() : boolean {
        return this.wrappingX === THREE.MirroredRepeatWrapping;
    }

    public set mirroredX(value:boolean) {
        this.wrappingX = value ? THREE.MirroredRepeatWrapping : THREE.RepeatWrapping;
    }

    public get mirroredY() : boolean {
        return this.wrappingY === THREE.MirroredRepeatWrapping;
    }

    public set mirroredY(value:boolean) {
        this.wrappingY = value ? THREE.MirroredRepeatWrapping : THREE.RepeatWrapping;
    }

    set(type:CBARTextureType, texture:CBARTexture|undefined) {
        //only one of each type
        const existing = this.values[type];
        if (existing) {
            this.remove(existing)
        }
        if (texture) {
            texture.type = type;
            this.add(texture)
        }
    }

    private _textures: Record<string, CBARTexture> = {};

    public get values()  {
        return this._textures
    }

    public add(asset:CBARTexture) {
        this._textures[asset.type] = asset
    }

    public containsKey(key:string) : boolean {
        return this._textures.hasOwnProperty(key);
    }

    public remove(asset:CBARTexture) {
        if (!this.containsKey(asset.type)) return;
        this._textures[asset.type].unload();
        delete this._textures[asset.type]
    }

    public length(): number {
        return Object.keys(this._textures).length
    }

    public clearAll() {
        this.all().forEach(t=>{
            t.unload();
        });
        this._textures = {}
    }

    public first() {
        const length = this.length();
        if (length) {
            return this.all()[0];
        }
    }

    public last() {
        const length = this.length();
        if (length) {
            return this.all()[length-1];
        }
    }

    public all() {
        return Object.values(this._textures)
    }


    load(basePath:string|undefined, json:CBARMaterialProperties) : Promise<CBARMaterial<T>> {

        const promises:Promise<CBARTexture>[] = [];

        if (json.ppcm) {
            this.ppcm = json.ppcm
        }

        if (json.ppi) {
            this.ppi = json.ppi;
        }

        this.scale = json.scale ? json.scale : 1.0;

        this.mirroredX = this.mirroredY = false;

        if (json.repeat === false) {
            this.wrappingX = this.wrappingY = THREE.ClampToEdgeWrapping;
        }
        else if (json.mirrored !== undefined) {
            this.mirroredX = this.mirroredY = json.mirrored;
        } else {
            if (json.mirroredX !== undefined) {
                this.mirroredX = json.mirroredX;
            }
            if (json.mirroredY !== undefined) {
                this.mirroredY = json.mirroredY;
            }
        }

        if (json.lightingFactor !== undefined) {
            this.lightingFactor = json.lightingFactor;
        }
        if (json.lightingOffset !== undefined) {
            this.lightingOffset = json.lightingOffset;
        }
        if (json.smoothstep !== undefined) {
            this.smoothstep = json.smoothstep;
        }

        let toRemove:string[] = [];
        if (json.textures) {
            toRemove = Object.keys(this._textures);

            for (const name in json.textures) {
                const index = toRemove.indexOf(name);
                let tex = this._textures[name];

                if (tex && index >=0) {
                    toRemove.splice(index, 1);
                } else {
                    tex = new CBARTexture(this.context, name as CBARTextureType);
                    this.add(tex);
                }
                promises.push(tex.load(basePath, json.textures[name], json.crop));
            }
        }

        if (json.properties) {
            for (const name in json.properties) {
                this.setMaterialProperty(name as CBARMaterialProperty, json.properties[name])
            }
        }

        return new Promise<CBARMaterial<T>>((resolve, reject)=>{

            if (promises.length) {
                Promise.all(promises).then((textures) => {

                    //remove previously used, and no longer in use
                    for (const remove of toRemove) {
                        const texture = this.values[remove];
                        texture.unload();
                        this.setTextureMap(texture);
                        this.remove(texture)
                    }

                    for (const texture of textures) {
                        const map = this.setTextureMap(texture);
                        if (map) {
                            //map.anisotropy = 16;
                        } else {
                            this.rejectPromise(reject, new CBARError(`Invalid texture type ${texture.type}`, CBARErrorCode.InvalidType, this));
                        }
                    }
                    resolve(this)
                }).catch((error) => {
                    reject(error)
                })
            } else {
            }
        })
    }

    public loadTexture(path:string, type:CBARTextureType, basePath?:string, crop?:ImageCrop) {
        const tex  = new CBARTexture(this.context, type);
        this.add(tex);
        return tex.load(basePath, path, crop);
    }

    data(): any {
        return {}
    }

    get description(): string {
        return "Material";
    }

    clone() {

        let copy = this.cloneObject();

        copy._material = this.threeMaterial.clone();

        copy.threeMaterial.onBeforeCompile = (shader) => {
            copy.onBeforeCompileCallback(shader)
        };

        return copy

    }

    public unload() {
        this.clearAll();
    }
}