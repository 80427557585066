import {CBARCollection} from "../CBARCollection";
import {CBAREvent, CBAREventType, CBARHighlightState, CBARMouseEvent, CBARToolMode} from "../CBARTypes";

import {CBARObject3DContainer} from "./CBARObject3DContainer";
import {CBARObject3D} from "./CBARObject3D";

import {CBARContext} from "../CBARContext";
import {
    CBARAmbientLight,
    CBARAsset,
    CBARDirectionalLight,
    CBARModelAsset,
    CBARPointLight,
    CBARRectAreaLight,
    CBARSpotlight
} from "../assets";
import {CBARLogLevel} from "../internal/Utils";
import * as THREE from "three";

export class CBARAssetCollection extends CBARObject3DContainer<CBARAsset> implements CBARCollection<CBARAsset> {
    private _assets: { [id: string] : CBARAsset; } = {};

    public constructor(context:CBARContext) {
        super(context)
    }

    public get values()  {
        return this._assets
    }

    public add(asset:CBARAsset) {
        this.log(`Added ${asset.description} ${asset.id}`, CBARLogLevel.Verbose);
        this._assets[asset.id] = asset;
        if (!asset.renderObject.parent) {
            asset.addToScene()
        }

        this.context.scene?.setAssetFor(CBARHighlightState.Selected, asset);
    }

    public containsKey(key:string) : boolean {
        return this._assets.hasOwnProperty(key);
    }

    public remove(asset:CBARAsset) {
        if (!this.containsKey(asset.id)) {
            console.log(`Asset ${asset.id} not found`);
            return;
        }

        if (asset.renderObject.parent) {
            asset.removeFromScene();
        }
        delete this._assets[asset.id]
    }

    public length(): number {
        return Object.keys(this._assets).length
    }

    public clearAll() {
        Object.values(this._assets).forEach(asset => {
            asset.removeFromScene();
            asset.unload()
        });
        this._assets = {}
    }

    public first() {
        const length = this.length();
        if (length) {
            return this.all()[0];
        }
    }

    public last() {
        const length = this.length();
        if (length) {
            return this.all()[length-1];
        }
    }

    public all() {
        return Object.values(this._assets)
    }

    protected generateAsset(json:any) : CBARObject3D<any> | null {

        if (json.type.endsWith("-light")) {
            switch (json.type) {
                case "ambient-light":
                    return new CBARAmbientLight(this.context);
                case "point-light":
                    return new CBARPointLight(this.context);
                case "directional-light":
                    return new CBARDirectionalLight(this.context);
                case "rect-area-light":
                    return new CBARRectAreaLight(this.context);
                case "spot-light":
                    return new CBARSpotlight(this.context)
            }
        } else if (json.type === "model") {
            return new CBARModelAsset(this.context)
        }

        return null //unsupported
    }

    load(basePath:string|undefined, json:any) : Promise<CBARObject3DContainer<CBARAsset>> {
        const promises:Promise<CBARAsset>[] = [];

        for (const assetJSON of json) {
            const asset = this.generateAsset(assetJSON);
            if (asset) {
                const path = assetJSON.id ? `${basePath}/assets/${assetJSON.id}` : basePath;
                promises.push(asset.load(path, assetJSON) as Promise<CBARAsset>)
            }
        }

        return new Promise<CBARObject3DContainer<CBARAsset>>((resolve, reject) => {
            Promise.all(promises).then((result)=>{
                //now add the assets
                result.forEach((asset)=>{
                    this.add(asset)
                });
                resolve(this)
            }).catch(error=>{
                reject(error)
            })
        })
    }

    public data() : any {
        const json:any[] = [];
        for (const key in this._assets) {
            const asset = this._assets[key];
            json.push(asset.data())
        }

        return json
    }

    public render() {
        for (const key in this._assets) {
            const asset = this._assets[key];
            asset.render()
        }
    }

    protected getObject3D(event: CBARMouseEvent): CBARObject3D<CBARAsset> | undefined {
        const intersection = event.intersections.find((obj)=>{
            return this._assets[obj.object.id]
        });

        if (intersection) {
            return this._assets[intersection.object.id]
        }
    }

    get description() : string {
        return "Asset collection"
    }

    sceneLoaded() {
        Object.values(this.values).forEach(object=>{
            object.sceneLoaded()
        })
    }

    toolModeChanged(mode:CBARToolMode) {
        Object.values(this.values).forEach(object=>{
            object.toolModeChanged(mode)
        })
    }

    getEventObjects(eventType:CBAREventType) : THREE.Object3D[] {
        let objects: THREE.Object3D[] = [];
        Object.values(this._assets).forEach(asset => {
            if (asset.receivesEvents) {
                objects.push(asset.renderObject)
            }
        });
        return objects
    }
}