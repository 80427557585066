import {CBARAsset, CBARAssetProperties, CBARTangibleAsset} from "./CBARAsset";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import {CBARError, CBARErrorCode} from "../CBARError";
import {CBARContext} from "../CBARContext";
import {CBARAssetType} from "../CBARTypes";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {ColladaLoader} from "three/examples/jsm/loaders/ColladaLoader";

export interface CBARModelAssetProperties extends CBARAssetProperties {
    model:string
}

export class CBARModelAsset extends CBARTangibleAsset {

    constructor(context:CBARContext) {
        super(context);
        this.setRenderObject(this._container)
    }

    public get type() : CBARAssetType {
        return CBARAssetType.Model
    }

    private _container = new THREE.Object3D();
    public modelPath?:string;

    load(basePath:string|undefined, json:CBARModelAssetProperties) : Promise<CBARAsset> {

        if (json.model) {
            this.modelPath = `${basePath}/${json.model}`
        }

        return new Promise<CBARAsset>((resolve, reject) => {
            super.load(basePath, json).then(()=>{
                if (!this.modelPath) return;
                this.log(`Loading model at ${this.modelPath}`);

                if (this.modelPath.endsWith('.gltf') || this.modelPath.endsWith('.glb')) {
                    const loader = new GLTFLoader();

                    //view models here: https://gltf-viewer.donmccurdy.com/
                    loader.load(this.modelPath, (gltf)=>{
                        this._container.add(gltf.scene);
                        this.log(`Model ${this.modelPath} loaded`);
                        resolve(this)
                    }, (progress)=>{
                        if (progress.total) {
                            const percentage = progress.loaded / progress.total;
                            this.log(`Model loading progress: ${100 * percentage}%`)
                        }
                    }, (error)=>{
                        this.log(error);
                        this.rejectPromise(reject, new CBARError(`Image at path ${this.modelPath} was not loaded`, CBARErrorCode.ImageNotFoundAtPath, this.modelPath));
                    })
                } else if (this.modelPath.endsWith('.fbx')) {
                    const loader = new FBXLoader();

                    //view models here: https://gltf-viewer.donmccurdy.com/
                    loader.load(this.modelPath, (model)=>{
                        this._container.add(model);
                        resolve(this)
                    }, (progress)=>{
                        if (progress.total) {
                            const percentage = progress.loaded / progress.total;
                            this.log(`Model loading progress: ${100 * percentage}%`)
                        }
                    }, (error)=>{
                        this.log(error);
                        this.rejectPromise(reject, new CBARError(`Image at path ${this.modelPath} was not loaded`, CBARErrorCode.ImageNotFoundAtPath, this.modelPath));
                    })
                } else if (this.modelPath.endsWith('.dae')) {
                    const loader = new ColladaLoader();

                    //view models here: https://gltf-viewer.donmccurdy.com/
                    loader.load(this.modelPath, (model)=>{

                        console.log(model);
                        this._container.add(model.scene);
                        resolve(this)
                    }, (progress)=>{
                        if (progress.total) {
                            const percentage = progress.loaded / progress.total;
                            this.log(`Model loading progress: ${100 * percentage}%`)
                        }
                    }, (error)=>{
                        this.log(error);
                        this.rejectPromise(reject, new CBARError(`Image at path ${this.modelPath} was not loaded`, CBARErrorCode.ImageNotFoundAtPath, this.modelPath));
                    })
                }

            })
        })
    }

    public data() : any {
        const data:any = {
            id:this.id,
        };
        if (this.modelPath) {
            data["model"] = this.modelPath
        }
        return data
    }

    get description() : string {
        return "Model"
    }
}