import {CBARSurfaceAsset, CBARSurfaceAssetProperties} from "./CBARSurfaceAsset";
import {CBARMaterialProperties, CBARMaterialProperty, CBARObject, CBARPaintMaterial, CBARSurface} from "../components";
import {CBARContext} from "../CBARContext";
import * as THREE from "three";
import {CBARStandardMaterial} from "../components/CBARStandardMaterial";
import {CBARAssetType} from "../CBARTypes";
import {CBARError, CBARErrorCode} from "../CBARError";

export interface CBARPaintAssetProperties extends CBARSurfaceAssetProperties {
    material:CBARMaterialProperties
}

export class CBARPaintAsset extends CBARSurfaceAsset {

    private sharedMaterial:CBARPaintMaterial;
    private _geometry:THREE.PlaneBufferGeometry;
    private _plane:THREE.Mesh;

    constructor(context:CBARContext) {
        super(context);

        this.sharedMaterial = new CBARPaintMaterial(context);
        this.sharedMaterial.setMaterialProperty(CBARMaterialProperty.color, 'rgb(241, 242, 235)');
        this.sharedMaterial.threeMaterial.depthTest = false;
        this.sharedMaterial.threeMaterial.clipShadows = true;
        this.sharedMaterial.threeMaterial.depthWrite = false;

        this._geometry = new THREE.PlaneBufferGeometry( 1, 1, 4);
        this._plane = new THREE.Mesh(this._geometry, this.sharedMaterial.threeMaterial);
        this.setRenderObject(this._plane)

        this.sharedMaterial.lightingFactor = 0.8;
        this.sharedMaterial.lightingOffset = 0.3;
    }

    public get type() : CBARAssetType {
        return CBARAssetType.PaintSurface
    }

    public get canMove() {
        return false
    }

    load(basePath: string|undefined, json:CBARPaintAssetProperties) : Promise<CBARPaintAsset> {

        const promises: Promise<CBARObject<any>>[] = [];

        if (json.material) {
            promises.push(this.sharedMaterial.load(basePath, json.material))
        }

        return new Promise<CBARPaintAsset>((resolve, reject) => {
            super.load(basePath, json).then(() => {
                Promise.all(promises).then(() => {
                    this.layoutSubTiles();
                    resolve(this)
                }).catch((error)=>{
                    reject(error)
                })
            }).catch(() => {
                this.rejectPromise(reject, new CBARError(`Cannot load ${this.description}`, CBARErrorCode.AssetLoad, this));
            })
        })
    }

    private layoutSubTiles() {
        if (!this.renderObject || !this.surface) return;

        const diameter = 2.0 * Math.sqrt(Math.pow(this.surface.extent.x, 2.0) + Math.pow(this.surface.extent.y, 2.0));

        this._geometry = new THREE.PlaneBufferGeometry( diameter, diameter, 2);
        this._plane.geometry = this._geometry;
        this.sharedMaterial.threeMaterial.depthTest = false;
    }

    addedToSurface(surface: CBARSurface): void {

        if (this.context.scene?.envMap) {
            this.sharedMaterial.threeMaterial.envMap = this.context.scene.envMap;
            this.sharedMaterial.threeMaterial.envMapIntensity = 0.5;
            this.sharedMaterial.threeMaterial.needsUpdate = true;
        }

        if (surface.maskTexture && surface.maskTexture.threeTexture) {
            this.sharedMaterial.threeMaterial.alphaMap = surface.maskTexture.threeTexture;
        }

        if (this.context.scene?.lightingTexture && this.context.scene.lightingTexture.threeTexture) {
            this.sharedMaterial.threeMaterial.lightMap = this.context.scene.lightingTexture.threeTexture;
            this.sharedMaterial.threeMaterial.metalnessMap = this.context.scene.lightingTexture.threeTexture;
        }

        this.layoutSubTiles()
    }

    public get color() {
        return this.sharedMaterial.getMaterialProperty(CBARMaterialProperty.color)
    }

    public set color(value) {
        this.sharedMaterial.setMaterialProperty(CBARMaterialProperty.color, value)
    }

    public get opacity() {
        return this.sharedMaterial.getMaterialProperty(CBARMaterialProperty.opacity)
    }

    public set opacity(value) {
        this.sharedMaterial.setMaterialProperty(CBARMaterialProperty.opacity, value)
    }

    get description(): string {
        return "Paint Asset"
    }
}