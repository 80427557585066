import process from "process";
import * as THREE from "three";
import {getConfig} from "../../backend";
import {getEnumValues} from "../CBARUtils";

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function isDev(): boolean
{
    return development;
}

export enum CBARLogLevel {
    Verbose = "verbose",
    Message = "message",
    Warning = "warning",
    Error = "error",
    MessageClient = "messages"
}

export type Size = {
    width:number
    height:number
}

function logLevelToInt(level:CBARLogLevel) {
    switch (level) {
        case CBARLogLevel.Verbose:
            return 0;
        case CBARLogLevel.Message:
            return 1;
        case CBARLogLevel.Warning:
            return 2;
        case CBARLogLevel.Error:
            return 3;
        case CBARLogLevel.MessageClient:
            return 4;
    }
}

export function log(message:any, logLevel?:CBARLogLevel) {

    if (logLevel === undefined) {
        logLevel = CBARLogLevel.Message
    }
    const logLevelValue = logLevelToInt(logLevel);
    const configLevelValue = logLevelToInt(getConfig().logLevel);

    if (logLevelValue < configLevelValue) {
        return
    }

    //todo: preserve line numbers: https://stackoverflow.com/questions/13815640/a-proper-wrapper-for-console-log-with-correct-line-number
    switch (logLevel) {
        case CBARLogLevel.Error:
            console.error(message);
            break;
        case CBARLogLevel.Warning:
            console.warn(message);
            break;
        case CBARLogLevel.Message:
        default:
            console.log(message);
    }
}

const pattern = /#include <(.*)>/gm;

export function parseIncludes( string:any ){
    function replace( match:any , include:any ){
        const replace = THREE.ShaderChunk[ include ];
        return parseIncludes( replace )
    }
    return string.replace( pattern, replace )
}

export function confineToEnum<T>(en:any, values:T[]) : T[] {
    const available = getEnumValues(en);
    const restricted = values.filter(value=>available.indexOf(value) >= 0);
    return restricted as T[]
}

export function randomIntFromInterval(min:number, max:number) { // min and max inclusive
    return Math.floor(Math.random() * (max - min + 1) + min);
}

//https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
export function uniq(a:any) {
    let seen:any = {};
    return a.filter(function(item:any) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}

export function normalizedToScreen(point2D:THREE.Vector2) : THREE.Vector2 {
    return new THREE.Vector2(point2D.x * 2.0 - 1.0, 1.0 - point2D.y * 2.0)
}

/**
 * @author Mikhail Zachepilo <mihailzachepilo@gmail.com>
 */

/**
 * @typedef {number} Ratio - number representation of ratio: width / height
 * @example 4 / 3 = 1.3333333333333333
 */

/**
 * @typedef {{width: number, height: number}} Size
 */

/**
 * @param {Ratio} ratio
 * @param {number} h
 */
export function getWidth(ratio: number, h: number): number {
    return ratio * h;
}

/**
 * @param {Ratio} ratio
 * @param {number} w
 */
export function getHeight(ratio: number, w: number): number {
    return w / ratio;
}

/**
 *
 * @param {Ratio} ratio
 * @param {number} maxWidth
 * @param {number} [maxHeight]
 * @return {Size}
 */
export function getMaxAvailableSize(ratio: number, maxWidth: number, maxHeight: number) {
    if (maxWidth) {
        const height = getHeight(ratio, maxWidth);

        if (height <= maxHeight) {
            return {
                height,
                width: maxWidth,
            };
        }
    }

    return {
        width: getWidth(ratio, maxHeight),
        height: maxHeight,
    };
}

/**
 *
 * @param {Ratio} ratio
 * @param {number} minWidth
 * @param {number} [minHeight]
 * @return {Size}
 */
export function getMinAvailableSize(ratio: number, minWidth: number, minHeight: number) {
    if (minWidth) {
        const height = getHeight(ratio, minWidth);

        if (height > minHeight) {
            return {
                height,
                width: minHeight,
            };
        }
    }

    return {
        width: getWidth(ratio, minHeight),
        height: minHeight,
    };
}

type TDeviceType = 'desktop' | 'tablet' | 'mobile';

export interface IDeviceInfo {
    type: TDeviceType
    height: number;
    width: number;
}

export type DeferredExecutor<T> = {
    resolve:(value: T | PromiseLike<T>) => void,
    reject:(reason: any) => void
}

export const getDeviceInfo = (): IDeviceInfo => {
    const { innerWidth, innerHeight } = window;
    let type: TDeviceType = 'desktop';

    if (innerWidth <= 1024 && innerWidth > 768 && innerHeight > 375) {
        type = 'tablet';
    } else if (innerWidth <= 768 || (innerWidth <= 812 && innerHeight <= 375)) {
        type = 'mobile';
    }

    return {
        type,
        width: innerWidth,
        height: innerHeight,
    };
};

export const shuffle = (array:any[]) => {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

// export const cvType2String = (type:number) {
//     string r;
//
//     uchar depth = type & CV_MAT_DEPTH_MASK;
//     uchar chans = 1 + (type >> CV_CN_SHIFT);
//
//     switch ( depth ) {
//         case CV_8U:  r = "8U"; break;
//         case CV_8S:  r = "8S"; break;
//         case CV_16U: r = "16U"; break;
//         case CV_16S: r = "16S"; break;
//         case CV_32S: r = "32S"; break;
//         case CV_32F: r = "32F"; break;
//         case CV_64F: r = "64F"; break;
//         default:     r = "User"; break;
//     }
//
//     r += "C";
//     r += (chans+'0');
//
//     return r;
// }

export const INT_MAX = 2147483647

export const toRadians = (degrees:number)=>{return degrees * Math.PI / 180; }