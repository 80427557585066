import * as gm from "gammacv";
import {CBARFeatureTracking} from "../../CBARContext";
import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import {CBAROpticalFlowNode} from "./CBAROpticalFlowNode";
import {DeferredExecutor} from "../Utils";
import {CBARLineFinderNode} from "./CBARLineFinderNode";
import {CBARClassifierNode} from "./CBARClassifierNode";
import {getConfig} from "../../../backend";

export enum CBARPipelineTask {
    HoughLines,
    OpticalFlow
}

type TaskHandler<T> = (data:T)=>boolean

export class CBARPipeline {

    public pipeline:gm.InputType;
    public session?:gm.Session;
    private _stream?:gm.CaptureVideo;

    public readonly input:gm.Tensor;
    public readonly output:gm.Tensor|null;

    public readonly greyscaleOp:gm.Operation|null;
    public readonly greyscaleOutput:gm.Tensor|null;
    public readonly greyscaleCanvas:HTMLCanvasElement;

    public readonly edgesOp?:gm.Operation|null;
    public readonly edgesOutput?:gm.Tensor|null;

    constructor(protected readonly canvas:HTMLCanvasElement, private readonly deviceID:string,
                public readonly tracking:CBARFeatureTracking=CBARFeatureTracking.None, public readonly downsample = 2) {

        this._nodes = [];
        this._stream = new gm.CaptureVideo(this.canvas.width, this.canvas.height);
        this.input = new gm.Tensor('uint8', [this.canvas.height, this.canvas.width, 4]);

        this.pipeline = gm.norm(this.input, 'minmax');
        this.pipeline = gm.grayscale(this.pipeline);

        if (this.downsample > 1) {
            this.pipeline = gm.downsample(this.pipeline, this.downsample);
        }

        this.greyscaleOp = this.pipeline;
        this.greyscaleOutput = gm.tensorFrom(this.greyscaleOp);
        this.greyscaleCanvas = gm.canvasCreate(this.greyscaleOutput!.shape[1], this.greyscaleOutput!.shape[0]);

        //get edges. tune here: https://gammacv.com/examples/canny_edges
        if (this.tracking  !== CBARFeatureTracking.None) {

            this.pipeline = gm.gaussianBlur(this.pipeline, 3, 2);
            this.pipeline = gm.sobelOperator(this.pipeline);

            this.edgesOp = gm.cannyEdges(this.pipeline, 0.1, 0.3);
            this.edgesOutput = gm.tensorFrom(this.edgesOp);
            this.pipeline = this.edgesOp;

            //find lines. tune here: https://gammacv.com/examples/pc_lines
            const layersCount = 2;
            const dStep = 2;
            const dCoeficient = 2.0;

            this.pipeline = gm.pcLinesTransform(this.pipeline, dStep);
            this.pipeline = gm.pcLinesEnhance(this.pipeline);

            //reduce:
            this.pipeline = gm.pcLinesReduceMax(this.pipeline, dCoeficient, 0);

            for (let i = 0; i < layersCount; i += 1) {
                this.pipeline = gm.pcLinesReduceMax(this.pipeline, dCoeficient, 1);
            }

            this._nodes.push(new CBAROpticalFlowNode(this));

            if (this.tracking === CBARFeatureTracking.World) {
                this._nodes.push(new CBARLineFinderNode(this));
            }
            else if (this.tracking === CBARFeatureTracking.Classifier || this.tracking === CBARFeatureTracking.Face) {
                //todo: in the case of face, use internal ones
                const path = getConfig().classifierPath;
                if (path) {
                    this._nodes.push(new CBARClassifierNode(this, path, getConfig().classifierMaxRegions));
                }
            }
        }

        this.session = new gm.Session()
        this.session.init(this.pipeline);
        this.output = gm.tensorFrom(this.pipeline);
    }

    public get nodes() {
        return this._nodes;
    }

    protected frameIndex = 0;
    public debugEnabled = true;

    protected tasks:DeferredExecutor<any>[] = []

    public requestTask<T>(task:CBARPipelineTask) {
        return new Promise<T>((resolve, reject) => {
            this.tasks[task] = {resolve, reject}
        });
    }

    private _subscriptions:{ [task: number]: TaskHandler<any>[]} = {};
    public subscribeToTask<T>(task:CBARPipelineTask, handler:TaskHandler<T>) {
        if (!this._subscriptions[task]) {
            this._subscriptions[task] = [];
        }
        this._subscriptions[task].push(handler);
    }

    public completeTask<T>(task:CBARPipelineTask, executor:()=>T) {
        const item = this.tasks[task];
        const subscriptions = this._subscriptions[task];
        if (item || subscriptions) {
            const result = executor();
            subscriptions?.forEach(handler=>handler(result));
            if (item) {
                item.resolve(result);
                delete this.tasks[task];
            }
        }
    }

    public update() {
        if (!this.session || !this._stream || !this.input){
            return
        }

        const frame = new CBARFrame(this, this.frameIndex);

        const activeNodes = this._nodes.filter(n=>n.enabled);

        //load in source image:
        this._stream.getImageBuffer(this.input);

        //process at each pipeline node
        activeNodes.forEach(n=>n.update(frame));

        frame.destroy();

        // draw raw frame into canvas
        gm.canvasFromTensor(this.canvas, this.input as gm.Tensor);

        //todo: hack: DRAW is intentional. For some reason mobile devices need whatever happens to the canvas in a draw command:
        gm.canvasDrawCircle(this.canvas, [0,0], 0, 'rgba(0, 0, 0, 0.1)');

        if (this.debugEnabled) {
            activeNodes.forEach(n=>n.debug(this.canvas));
        }

        this.frameIndex += 1;
    }

    public start() {
        console.log("Feature tracking is " + this.tracking)
        return this._stream!.start(this.deviceID);
    }

    private _nodes:CBARProcessNode[] = [];

    public release() {
        this._nodes.forEach(n=>n.destroy());

        this._stream?.stop();
        this._stream = undefined;
        this.session?.destroy();
        this.session = undefined;
        this.input.release();
    }
}