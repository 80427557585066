import {CBARObject} from "./CBARObject";
import * as THREE from "three";
import {MathUtils} from "three";
import {CBAREvent, CBARHighlightState, CBARToolMode} from "../CBARTypes";
import {CBARLogLevel} from "../internal/Utils";
import generateUUID = MathUtils.generateUUID;

export interface CBARObject3DProperties {
    name?:string,
    visible?:boolean
    scale?:number[],
    rotation?:number[],
    position?:number[]
}

export interface CBARHighlightStateData {
    active:boolean
    event?:CBAREvent
}

export abstract class CBARObject3D<T> extends CBARObject<T> {

    public name:string = generateUUID();
    public visible = true;

    public rootObject() : CBARObject3D<any> {
        return this
    }

    public data() : any {

        const data:any = {
            id:this.id,
        };

        if (this.name) {
            data.name = this.name
        }

        if (this.renderObject) {
            data.position = [this.renderObject.position.x, this.renderObject.position.y, this.renderObject.position.z];
            data.rotation = [this.renderObject.rotation.x, this.renderObject.rotation.y, this.renderObject.rotation.z];
            data.scale = [this.renderObject.scale.x, this.renderObject.scale.y, this.renderObject.scale.z]
        }

        return data
    }

    private readonly _states = new Map<CBARHighlightState, CBARHighlightStateData|undefined>();

    public get selected() {
        return this.getState(CBARHighlightState.Selected).active;
    }

    public set selected(value:boolean) {
        this.setState(CBARHighlightState.Selected, value);
    }

    public setState(key:CBARHighlightState, active:boolean, event?:CBAREvent) {
        this._states.set(key, {active, event});
    }

    public getState(key:CBARHighlightState) : CBARHighlightStateData {
        const value = this._states.get(key);
        return value ? value : {active:false};
    }

    public isActive(state:CBARHighlightState) : boolean {
        const value = this._states.get(state);
        return value ? value.active : false;
    }

    load(basePath:string|undefined, json:CBARObject3DProperties) : Promise<T> {

        if (json.hasOwnProperty('name') && json.name) {
            this.name = json.name
        }

        return new Promise<any>((resolve) => {

            if (this.renderObject) {

                if (json.scale) {
                    this.renderObject.scale.set(json.scale[0], json.scale[1], json.scale[2])
                }

                if (json.rotation) {
                    this.renderObject.setRotationFromEuler(new THREE.Euler(json.rotation[0], json.rotation[1], json.rotation[2]))
                }

                if (json.position) {
                    this.renderObject.position.set(json.position[0], json.position[1], json.position[2])
                }

                if (json.visible !== undefined) {
                    this.renderObject.visible = json.visible
                }

                this.renderObject.updateMatrix();
                this.context.refresh();
            }

            resolve(this)
        })
    }

    protected updateObjectPosition() : void {
        const obj = this.renderObject;
        if (!obj) return
    }

    public needsUpdate() {
        //maybe more complex update logic here
        this.context.refresh();
    }

    protected willAddToScene() : void {
        const obj = this.renderObject;
        if (!obj) return;
        obj.updateMatrix();
    }

    sceneLoaded() : void {}

    toolModeChanged(mode:CBARToolMode) {}

    private _object = new THREE.Object3D();

    public get renderObject() : THREE.Object3D {
        return this._object
    }

    protected setRenderObject(obj:THREE.Object3D) {
        this._object = obj;
        this.id = obj.uuid;
        (obj as any).__cbarObject = this;
        this.needsUpdate();
    }

    public get receivesEvents() : boolean {
        return this.visible
    }

    public existsAtPoint(coords:THREE.Vector2) : boolean {
        return true
    }

    public handleEvent(event: CBAREvent) {}

    addToScene() : void {
        this.willAddToScene();

        const length = this.context.gl.scene.children.length;

        if (!this.renderObject.parent) {
            this.context.gl.scene.add(this.renderObject)
        }

        if (length === this.context.gl.scene.children.length) {
            this.log(`CBARObject3D ${this.name} could not be added. Has it already been added?`, CBARLogLevel.Warning)
        }
    }

    removeFromScene() : void {

        const parent = this.renderObject.parent;

        if (parent) {
            const length = parent.children.length;

            parent.remove(this.renderObject);

            if (length === parent.children.length) {
                console.log(`CBARObject3D ${this.name} could not be removed.`, CBARLogLevel.Warning)
            }
        } else {
            console.log("Object not removed", this.renderObject);
        }

        const scene = this.context.gl.scene;
        if (scene) {
            scene.remove(this.renderObject);
        }
    }

    public render() {}
}
