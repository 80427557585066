import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import {CBARPipeline} from "./CBARPipeline";
import {CascadeClassifier, Mat} from "mirada";
import {Rect} from "gammacv";
import {CBARFeatureTrackerNode} from "./CBARFeatureTrackerNode";
import {CBARTrackedObject, CBARTrackedPoint} from "./CBARTrackedObject";
import {rectsOverlap} from "../Math";

const dataFiles:string[] = [];

//Training the classifier:
//https://memememememememe.me/post/training-haar-cascades/

class CBARTrackedRegion extends CBARTrackedObject {

    constructor(rect:Rect, frameIndex:number, frameTime:number, maxAge:number) {
        const points:CBARTrackedPoint[] = [];

        points.push(new CBARTrackedPoint(new cv.Point(rect.ax,rect.ay)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.bx,rect.by)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.cx,rect.cy)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.dx,rect.dy)));
        super(points, frameIndex, frameTime, maxAge);
    }

    public get roi():Rect {
        return new Rect(
            this.points[0].point.x, this.points[0].point.y,
            this.points[1].point.x, this.points[1].point.y,
            this.points[2].point.x, this.points[2].point.y,
            this.points[3].point.x, this.points[3].point.y,
        );
    }

    isMatch(candidate: CBARTrackedRegion): boolean {
        //const ratio = Math.min(this.roi.area, candidate.roi.area) / Math.max(this.roi.area, candidate.roi.area);
        return rectsOverlap(this.roi, candidate.roi)
    }
}

export class CBARClassifierNode extends CBARProcessNode {

    constructor(context:CBARPipeline, classifierPath:string, private maxRegions:number, private updateMS = 1000, private scale = 0.5) {
        super(context)
        this._classifier = new cv.CascadeClassifier();
        console.log("Loading classifier at", classifierPath);

        const fileName = classifierPath.split("/").pop() as string;

        if (dataFiles.indexOf(fileName) >= 0) {
            this._classifier.load(fileName);
        } else {
            fetch(classifierPath).then(response=>{
                response.arrayBuffer().then((buffer)=>{
                    // do something with buffer
                    let data = new Uint8Array(buffer);
                    cv.FS.createDataFile('/', fileName, data, true, false, false);
                    dataFiles.push(fileName);

                    this._classifier.load(fileName);
                    console.log("Loaded classifier file", fileName);
                });
            })
        }
    }

    private _classifier:CascadeClassifier;

    private _tracker:CBARFeatureTrackerNode = new CBARFeatureTrackerNode(this.context, {
        maxFeatures:this.maxRegions
    });

    private _lastExecTime?:number;
    private _busy = false;

    update(frame:CBARFrame):void {
        const age = this._lastExecTime ? frame.time - this._lastExecTime : Number.MAX_SAFE_INTEGER;

        this._tracker.update(frame);

        if (this._classifier.empty() || this._busy || age < this.updateMS) return;

        this._busy = true;
        this._lastExecTime = frame.time;

        const greyscale = frame.greyscaleImage.clone();
        cv.resize(greyscale, greyscale, new cv.Size(this.scale * greyscale.cols,this.scale * greyscale.rows));
        const rescale = this.scale / this.context.downsample;

        this.detect(greyscale, rescale, frame.index, frame.time).finally(()=>{
            greyscale.delete();
            this._busy = false;
        })
    }

    //perspective transform https://github.com/PeculiarVentures/GammaCV/issues/28
    private _featureAspectRatio:number = 1

    private async detect(greyscale:Mat, downscale:number, frameIndex:number, frameTime:number) {
        const matches = new cv.RectVector();
        this._classifier.detectMultiScale(greyscale, matches, 1.1, 3, 0);

        for (let i = 0; i < matches.size(); ++i) {
            let match = matches.get(i);

            this._featureAspectRatio = match.width / match.height;

            const rect = new Rect(match.x / downscale, match.y / downscale,
                (match.x + match.width) / downscale, match.y / downscale,
                (match.x + match.width) / downscale, (match.y + match.height) / downscale,
                match.x / downscale, (match.y + match.height) / downscale);

            this._tracker.track(new CBARTrackedRegion(rect, frameIndex, frameTime, this.updateMS * 4));
        }
    }

    debug(canvas:HTMLCanvasElement):void {
        this._tracker.debug(canvas);
    }

    destroy() {
        this._tracker.destroy();
    }
}