import * as THREE from "three";
import * as React from "react";
import {createRef} from "react";
import * as gm from "gammacv";
import {CBARMode} from "../CBARTypes";
import {CBARLogLevel, getDeviceInfo, log} from "./Utils";
import {CBARCameraFacing, CBARContext, CBARFeatureTracking} from "../CBARContext";
import {DeviceOrientationControls} from "three/examples/jsm/controls/DeviceOrientationControls";
import {Point} from "mirada";
import {CBARPipeline} from "./pipeline/CBARPipeline";

export type CBARMediaInfo = {
    width:number,
    height:number
}

type CBARMediaViewProps = {
    context?:CBARContext

    onMediaReady:(view:CBARMediaView) => void
    onMediaUpdated:(view:CBARMediaView, info:CBARMediaInfo) => void
}

enum CBARMediaViewErrorCode {
    PermissionDenied='PermissionDenied'
}

enum CBARMediaViewErrorLevel {
    Message,
    Warning,
    Critical,
}

type CBARMediaViewError = {
    code:CBARMediaViewErrorCode,
    message?:string,
    level:CBARMediaViewErrorLevel
}

type CBARMediaViewState = {
    context?:CBARContext
    error?:CBARMediaViewError
    hasCameraAccess?:boolean
}

export class CBARMediaView extends React.Component<CBARMediaViewProps, CBARMediaViewState> {

    private container = createRef<HTMLDivElement>();
    private canvasTexture = new THREE.Texture(gm.canvasCreate(1024,1024));

    constructor(props:CBARMediaViewProps) {
        super(props);

        this.state = {
            context:props.context,
            hasCameraAccess:false
        }
    }

    componentDidMount(): void {

    }

    hasInitialized = false;
    device = getDeviceInfo();

    componentDidUpdate(props:CBARMediaViewProps) {

        if (props.context && !this.hasInitialized) {
            this.hasInitialized = true;
            props.context.gl.scene.background = this.canvasTexture;

            this.canvasTexture.minFilter = THREE.LinearFilter;
            this.canvasTexture.magFilter = THREE.LinearFilter;
            this.canvasTexture.format = THREE.RGBFormat;

            const canvas = this.canvasTexture.image;

            canvas.width = 1080;
            canvas.height = 1080;

            this.props.onMediaReady(this)
        }
    }

    protected getPipeline(input: gm.Tensor) : gm.Operation {
        //identity
        return gm.norm(input, 'minmax')
    }

    public loadImage(image:HTMLImageElement) {
        const canvas = this.canvasTexture.image;
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;

        if (ctx) {
            ctx.drawImage(image, 0,0);
            this.stopVideoCamera();
        }

        console.log(`Scene image loaded at ${image.width} x ${image.height}`);

        this._mode = CBARMode.Image;

        this.props.onMediaUpdated(this, {width:canvas.width, height:canvas.height})
    }

    private _mode = CBARMode.None;

    public get mode() : CBARMode {
        return this._mode
    }

    renderError = (error:CBARMediaViewError) => {

        if (error.code === CBARMediaViewErrorCode.PermissionDenied) {
            return <div className={"restart-camera"} onClick={()=>this.startVideoCamera(this.featureTracking, this.facing)}>Restart Camera</div>
        } else {
            if (this.device.type === "desktop") {
                const logger = error.level === CBARMediaViewErrorLevel.Critical ? console.error : (error.level === CBARMediaViewErrorLevel.Warning ? console.warn : console.log)
                logger(error.message ? error.message : error.code);
            }
            return <div className={`error-level-${error.level}`}>
                ${error.code}: ${error.message}
            </div>
        }
    }

    public startVideoCamera(tracking: CBARFeatureTracking, facing:CBARCameraFacing) : Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            if (!this.container.current) {
                reject();
                return;
            }
            const container = this.container.current;
            const canvas = this.canvasTexture.image;

            this._mode = CBARMode.Video;
            this._featureTracking = tracking;
            this._facing = facing;

            let facingMode:string|undefined = facing;

            //default is not a real mode in the camera API, so get the best for type
            if (facing === CBARCameraFacing.Default) {
                facingMode = tracking === CBARFeatureTracking.Face ? CBARCameraFacing.User : CBARCameraFacing.Environment;
            }

            const constraints = {
                audio: false,
                video: {
                    facingMode: facingMode,
                    width: { ideal: this.device.type === "mobile" ? 720 : 1280 },
                    height: { ideal: this.device.type === "mobile" ? 720 : 1280 }
                }
            };

            try {
                // enumerate devices and select the first camera (mostly the back one)
                navigator.mediaDevices.getUserMedia(constraints).then((s) => {
                    const tracks = s.getVideoTracks();
                    if (tracks.length > 0) {
                        const track = tracks[0];
                        const props = track.getSettings();
                        if (props.deviceId) {
                            canvas.width = props && props.width ? props.width : container.offsetWidth; //* window.devicePixelRatio
                            canvas.height = props && props.height ? props.height : container.offsetHeight; //* window.devicePixelRatio
                            this.pipeline = new CBARPipeline(canvas, props.deviceId, tracking);

                            if (props.facingMode === CBARCameraFacing.User || this.device.type === "desktop" || track.label.toLowerCase().indexOf("facetime") >= 0) {
                                //flip image for user
                                this.canvasTexture.wrapS = THREE.RepeatWrapping;
                                this.canvasTexture.repeat.x = - 1;
                            }

                            this.props.onMediaUpdated(this, {width:canvas.width, height:canvas.height})

                            this.setState({ hasCameraAccess: true });

                            console.log("Starting video camera");

                            this.pipeline.start().then(()=>{
                                console.log(`Video camera started at ${canvas.width} x ${canvas.height}`);
                                resolve();
                            }).catch(error=>{
                                tracks[0].stop();
                                this.pipeline?.release();
                                this.pipeline = undefined;
                                reject(error);
                            })
                            return;
                        }
                    }
                    //No tracks available
                    reject();
                }).catch((error)=>{
                    this.setState({ error: {code:CBARMediaViewErrorCode.PermissionDenied, message:error.message, level:CBARMediaViewErrorLevel.Critical}});
                    reject(error);
                })
            } catch (error) {
                this.setState({ error: {code:CBARMediaViewErrorCode.PermissionDenied, message:error.message, level:CBARMediaViewErrorLevel.Critical}});
                reject(error);
            }
        })

    }

    public stopVideoCamera() {
        if (this.pipeline) {
            this.pipeline.release();
            this.pipeline = undefined;
            this.orientationControls?.dispose();
            this.orientationControls = undefined;
            this._mode = CBARMode.None;
            log(`Video camera stopped`, CBARLogLevel.Verbose)
        }
    }

    public captureImage() : Promise<HTMLImageElement> {

        const canvas = this.canvasTexture.image;
        const url = canvas.toDataURL();

        return new Promise(resolve => {
            const img = document.createElement("img");

            img.onload = () => {
                log(`Image captured at ${img.width} x ${img.height}`, CBARLogLevel.Verbose);
                // no longer need to read the blob so it's revoked
                resolve(img)
            };

            img.src = url;
        })
    }

    private _featureTracking = CBARFeatureTracking.None;

    public get featureTracking() {
        return this._featureTracking;
    }

    private _facing = CBARCameraFacing.Environment;

    public get facing() {
        return this._facing;
    }

    private pipeline?:CBARPipeline;

    //https://github.com/opencv/opencv/blob/68d15fc62edad980f1ffa15ee478438335f39cc3/modules/imgproc/src/hough.cpp

    _trackingPoints:Point[] = [];

    private orientationControls?:DeviceOrientationControls

    private getAccelerometer = () => {
        //only on non desktop devices

        const dm = DeviceMotionEvent as any;
        if (dm) {
            if (dm.requestPermission) {
                //promptGyroPermission()
            } else {
                this.orientationControls = new DeviceOrientationControls(this.context.gl.camera);
            }
        }
    }

    update = () => {
        this.orientationControls?.update();
        this.pipeline?.update();
        this.canvasTexture.needsUpdate = true
    };

    render() {
        return <div ref={this.container} />
    }
}