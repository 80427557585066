import {CBARSurface, CBARSurfaceProperties} from "./CBARSurface";
import {CBARCollection} from "../CBARCollection";
import {CBARScene} from "./CBARScene";
import {CBAREvent, CBAREventType, CBARMouseEvent, CBARSurfaceType, CBARToolMode} from "../CBARTypes";
import {CBARObject3DContainer} from "./CBARObject3DContainer";
import {CBARContext} from "../CBARContext";
import {CBARObject3D, CBARObject3DProperties} from "./CBARObject3D";
import * as THREE from "three";
import {getConfig} from "../../backend";

export interface CBARSceneGeometryProperties extends CBARObject3DProperties {
    surfaces:CBARSurfaceProperties[]
}

export class CBARSceneGeometry extends CBARObject3DContainer<CBARSurface> implements CBARCollection<CBARSurface> {

    private _surfaces: { [id: string] : CBARSurface; } = {};

    public get values()  {
        return this._surfaces
    }

    public get surfaces()   {
        return Object.values(this._surfaces)
    }

    public add(surface:CBARSurface) {
        if (surface.id) {
            this._surfaces[surface.id] = surface;
            surface.addToScene()
        }
    }

    public containsKey(key:string) : boolean {
        return this._surfaces.hasOwnProperty(key);
    }

    public remove(surface:CBARSurface) {
        if (!this.containsKey(surface.id)) return;

        surface.removeFromScene();
        delete this._surfaces[surface.id]
    }

    public length(): number {
        return Object.keys(this._surfaces).length
    }

    public first() {
        const length = this.length();
        if (length) {
            return this.all()[0];
        }
    }

    public last() {
        const length = this.length();
        if (length) {
            return this.all()[length-1];
        }
    }

    public all() {
        return Object.values(this._surfaces)
    }


    public clearAll() {
        Object.values(this._surfaces).forEach(surface => {
            surface.removeFromScene();
            surface.unload()
        });
        this._surfaces = {}
    }

    load(basePath:string|undefined, json:CBARSceneGeometryProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) : Promise<CBARSceneGeometry> {
        const promises:Promise<CBARSurface>[] = [];

        let numWalls = 0;
        if (json.surfaces) {
            for (const surfaceJSON of json.surfaces) {
                if (surfaceTypes && surfaceJSON.type && surfaceTypes.indexOf(surfaceJSON.type) < 0) {
                    continue; //skip
                }
                else if (surfaceJSON.type === CBARSurfaceType.Wall) {
                    numWalls ++;
                    if (numWalls > getConfig().maxWallCount) {
                        if (numWalls == getConfig().maxWallCount + 1) {
                            console.log(`Wall count of ${getConfig().maxWallCount} exceeded`);
                        }
                        continue;
                    }
                }

                const surface = new CBARSurface(this.context);
                promises.push(surface.load(basePath, surfaceJSON, room, subroom))
            }
        }

        return new Promise<CBARSceneGeometry>((resolve, reject) => {
            Promise.all(promises).then((result)=>{
                result.forEach((surface)=>{
                    this.add(surface)
                });
                resolve(this)
            }).catch(error=>{
                reject(error)
            })
        })
    }

    public data() : any {

        const surfaceJSON:any[] = [];

        for (const key in this._surfaces) {
            const surface = this._surfaces[key];
            surfaceJSON.push(surface.data())
        }

        return {
            surfaces:surfaceJSON
        }
    }

    public render() {

        Object.values(this._surfaces).forEach(surface => {
            surface.render()
        })
    }

    protected getObject3D(event: CBARMouseEvent): CBARObject3D<CBARSurface> | undefined {
        const intersection = event.intersections.find((int)=>{
            return this._surfaces[int.object.id]
        });

        if (intersection) {
            return this._surfaces[intersection.object.id]
        }
    }

    get description() : string {
        return "Scene Geometry"
    }

    sceneLoaded() {
        Object.values(this.values).forEach(object=>{
            object.sceneLoaded()
        })
    }

    toolModeChanged(mode:CBARToolMode) {
        Object.values(this.values).forEach(object=>{
            object.toolModeChanged(mode)
        })
    }

    getEventObjects(eventType:CBAREventType) : THREE.Object3D[] {
        let objects: THREE.Object3D[] = [];
        Object.values(this._surfaces).forEach(surface => {
            if (surface.receivesEvents) {
                objects.push(surface.renderObject)
            }
        });
        return objects
    }
}
