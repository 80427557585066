import {CBARLogLevel} from "../core/internal/Utils";
import {BackendConfig} from "cambrian-base";

export class ProcessImageResults {
    constructor(readonly semanticUrl: string, readonly lightingUrl: string,
                readonly dataUrl: string | undefined, readonly superpixelsUrl: string | undefined,
                readonly fieldOfView: number, readonly cameraRotation: [number, number, number],
                readonly cameraElevation: number, readonly floorRotation: number) {

    }
}

export interface ImageProcessor {
    processImage(imageId: string, params: { [key: string]: string}): Promise<ProcessImageResults | null>
}

export type GetUploadUrlsRequest = {
    key?: string // When defined, will request sub-urls for existing room
    names: string[] // Sub-names like mask, preview
}

export type GetUploadUrlsResult = {
    key: string // Room id
    uploadUrl?: string // Upload url for roomId's background
    subKey: string // Sub-room id for the room id
    subUploadUrls: { [name: string]: string } // Sub-upload urls for requested names for subRoomId
}

export interface ImageUploader {
    getUploadUrls(request: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null>
    upload(url: string, content: File | Blob): Promise<boolean>
}

export class CambrianImageProcessor implements ImageProcessor {
    constructor(readonly segmentUrl: string) {

    }

    async processImage(imageId: string, params: { [key: string]: string}): Promise<ProcessImageResults | null> {
        let segmentUrl = `${this.segmentUrl}/${imageId}?`;

        Object.keys(params).forEach((key, index)=>{
            const kvString = `${key}=${params[key]}`;
            segmentUrl += (index ? `&${kvString}` : kvString);
        });

        console.log(`Segmenting at ${segmentUrl}`);

        const response = await fetch(segmentUrl);

        if (response.ok) {
            const responseData = await response.json();
            return new ProcessImageResults(
                responseData.semantic_url,
                responseData.lighting_url,
                responseData.hasOwnProperty("data_v3_url") ? responseData.data_v3_url : responseData.data_url,
                responseData.superpixels_url,
                responseData.fov,
                responseData.camera_rotation,
                responseData.camera_elevation,
                responseData.floor_rotation)
        }

        return null
    }
}

export class PresignedUrlImageUploader implements ImageUploader {
    constructor(readonly uploadUrl: string) {
        //console.log(`Using PresignedUrlImageUploader at ${uploadUrl}`)
    }

    async getUploadUrls(req: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null> {

        let url = `${this.uploadUrl}/?imagenames=${req.names.join(",")}`;
        if (req.key) {
            url += `&key=${req.key}`
        }

        const presignedResponse = await fetch(url);
        if (!presignedResponse.ok) {
            console.warn("Response was invalid");
            return null
        }

        const presignedResponseData = await presignedResponse.json();

        if (presignedResponseData.key) {
            return {
                key: presignedResponseData.key,
                uploadUrl: presignedResponseData.uploadUrl,
                subKey: presignedResponseData.subKey,
                subUploadUrls: presignedResponseData.subUploadUrls
            }
        }

        return null
    }

    async upload(url: string, content: File | Blob): Promise<boolean> {
        // Upload the content to the presigned url
        const uploadResponse = await fetch(url, {
            method: "PUT",
            body: content
        });

        return uploadResponse.ok
    }
}

export class DirectImageUploader implements ImageUploader {
    constructor(readonly uploadUrl:string) {
        console.log(`Using DirectImageUploader at ${uploadUrl}`)
    }

    async getUploadUrls(req: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null> {
        throw new Error("getUploadUrls not implemented for DirectImageUploader")
    }

    async upload(url: string, content: File | Blob): Promise<boolean> {
        throw new Error("upload not implemented for DirectImageUploader")
    }
}

export type CBServerConfigExternal = BackendConfig & {
    logLevel?:CBARLogLevel
    maxWallCount?:number,

    //appearance
    placeholderPath?:string,
    hoverBGOpacity?:number,
    clickBGOpacity?:number,
    selectedBGOpacity?:number,

    hoverOutlineOpacity?:number,
    clickOutlineOpacity?:number,
    selectedOutlineOpacity?:number,

    //specific
    classifierPath?:string
    classifierMaxRegions?:number
}

export type CBServerConfig = {
    logLevel:CBARLogLevel,
    maxWallCount:number,

    //appearance
    hoverBGOpacity:number,
    clickBGOpacity:number,
    selectedBGOpacity:number,

    hoverOutlineOpacity:number,
    clickOutlineOpacity:number,
    selectedOutlineOpacity:number,
    classifierMaxRegions:number

} & CBServerConfigExternal

let _config:CBServerConfig|undefined;

// Make sure either direct upload url or presigned upload url are defined, and not both (exclusive or).
export function cbInitialize(config:CBServerConfigExternal) {

    _config = { ...config,
        logLevel:config.logLevel ? config.logLevel : CBARLogLevel.Error,

        hoverBGOpacity: config.hoverBGOpacity ? config.hoverBGOpacity : 0.0,
        clickBGOpacity: config.clickBGOpacity ? config.clickBGOpacity : 0.0,
        selectedBGOpacity: config.selectedBGOpacity ? config.selectedBGOpacity : 0.0,

        hoverOutlineOpacity: config.hoverOutlineOpacity ? config.hoverOutlineOpacity : 0.0,
        clickOutlineOpacity: config.clickOutlineOpacity ? config.clickOutlineOpacity : 1.0,
        selectedOutlineOpacity: config.selectedOutlineOpacity ? config.selectedOutlineOpacity : 0.5,
        maxWallCount:config.maxWallCount !== undefined ? config.maxWallCount : 100,

        classifierMaxRegions:config.classifierMaxRegions !== undefined ? config.classifierMaxRegions : 1
    };
}

export function getConfig():CBServerConfig {
    if (!_config) {
        throw Error("react-home-ar is not initialized, call cbInitialize(config:CBServerConfig)")
    }
    return _config
}