import {Line} from "gammacv";
import {Size} from "./Utils";
import * as THREE from "three";

export const clipLine = (size:Size, line:Line) =>
{
    //const [c1, c2] = [0, 0];
    const right = size.width-1, bottom = size.height-1;

    if( size.width <= 0 || size.height <= 0 )
        return false;

    //int64 &x1 = pt1.x, &y1 = pt1.y, &x2 = pt2.x, &y2 = pt2.y;
    let c1 = (line.x1 < 0 ? 1 : 0) + (line.x1 > right ? 1 : 0) * 2 + (line.y1 < 0 ? 1 : 0) * 4 + (line.y1 > bottom ? 1 : 0) * 8;
    let c2 = (line.x2 < 0 ? 1 : 0) + (line.x2 > right ? 1 : 0) * 2 + (line.y2 < 0 ? 1 : 0) * 4 + (line.y2 > bottom ? 1 : 0) * 8;

    if( (c1 & c2) == 0 && (c1 | c2) != 0 )
    {
        let a=0;
        if ((c1 & 12) > 0)
        {
            a = c1 < 8 ? 0 : bottom;
            line.x1 += (a - line.y1) * (line.x2 - line.x1) / (line.y2 - line.y1);
            line.y1 = a;
            c1 = (line.x1 < 0 ? 1 : 0) + (line.x1 > right ? 1 : 0) * 2;
        }
        if( (c2 & 12) > 0)
        {
            a = c2 < 8 ? 0 : bottom;
            line.x2 += (a - line.y2) * (line.x2 - line.x1) / (line.y2 - line.y1);
            line.y2 = a;
            c2 = (line.x2 < 0 ? 1 : 0) + (line.x2 > right ? 1 : 0) * 2;
        }
        if( (c1 & c2) == 0 && (c1 | c2) != 0 )
        {
            if( c1 > 0)
            {
                a = c1 == 1 ? 0 : right;
                line.y1 += (a - line.x1) * (line.y2 - line.y1) / (line.x2 - line.x1);
                line.x1 = a;
                c1 = 0;
            }
            if( c2 > 0)
            {
                a = c2 == 1 ? 0 : right;
                line.y2 += (a - line.x2) * (line.y2 - line.y1) / (line.x2 - line.x1);
                line.x2 = a;
                c2 = 0;
            }
        }
    }

    //pt1.x = x1; pt1.y = y1; pt2.x = x1; pt2.y = y1;

    return (c1 | c2) == 0;
}

export const iterateLine = (line:Line, iterator:(x:number, y:number)=>void) => {
    //opencv LineIterator class
    //https://github.com/opencv/opencv/blob/68d15fc62edad980f1ffa15ee478438335f39cc3/modules/imgproc/src/drawing.cpp

    let dx = line.x2 - line.x1;
    let dy = line.y2 - line.y1;

    let x = line.x1;
    let y = line.y1;

    let dx1 = 0, dy1 = 0, dx2 = 0, dy2 = 0;
    if (dx<0) dx1 = -1 ; else if (dx>0) dx1 = 1 ;
    if (dy<0) dy1 = -1 ; else if (dy>0) dy1 = 1 ;
    if (dx<0) dx2 = -1 ; else if (dx>0) dx2 = 1 ;
    let longest = Math.abs(dx) ;
    let shortest = Math.abs(dy) ;
    if (!(longest>shortest)) {
        longest = Math.abs(dy) ;
        shortest = Math.abs(dx) ;
        if (dy<0) dy2 = -1 ; else if (dy>0) dy2 = 1 ;
        dx2 = 0 ;
    }
    let numerator = longest >> 1;

    for (let i=0;i<=longest;i++) {

        iterator(x,y);

        numerator += shortest ;
        if (!(numerator<longest)) {
            numerator -= longest ;
            x += dx1 ;
            y += dy1 ;
        } else {
            x += dx2 ;
            y += dy2 ;
        }
    }
}

export const getLinePoints = (line:Line) => {
    const points:THREE.Vector2[] = [];
    iterateLine(line, (x,y)=>{
        points.push(new THREE.Vector2(x,y))
    })

    return points;
}

export function angleBetweenLines(line1:Line, line2:Line) {
    const dx1 = line1.data[2] - line1.data[0];
    const dy1 = line1.data[3] - line1.data[1];
    const dx2 = line2.data[2] - line2.data[0];
    const dy2 = line2.data[3] - line2.data[1];

    const d = dx1 * dx2 + dy1 * dy2;
    const l2 = (dx1 * dx1 + dy1 * dy1) * (dx2 * dx2 + dy2 * dy2);

    return Math.acos(d / Math.sqrt(l2));
}

export const lineMidpoint = (line:Line) => {
    return new cv.Point((line.x1 + line.x2) / 2.0, (line.y1 + line.y2) / 2.0);
}

export const mergeLinePoints = (ax:number, ay:number, bx:number, by:number, cx:number, cy:number, dx:number, dy:number) => {
    let thi;
    let thj;
    let thr;

    const dlix = (bx - ax);
    const dliy = (by - ay);
    const dljx = (dx - cx);
    const dljy = (dy - cy);

    const li = Math.sqrt((dlix * dlix) + (dliy * dliy));
    const lj = Math.sqrt((dljx * dljx) + (dljy * dljy));

    const xg = (li * (ax + bx) + lj * (cx + dx)) / (2.0 * (li + lj));
    const yg = (li * (ay + by) + lj * (cy + dy)) / (2.0 * (li + lj));

    if (dlix == 0.0) thi = Math.PI / 2.0;
    else thi = Math.atan(dliy / dlix);

    if (dljx == 0.0) thj = Math.PI / 2.0;
    else thj = Math.atan(dljy / dljx);

    if (Math.abs(thi - thj) <= Math.PI / 2.0)
        thr = (li * thi + lj * thj) / (li + lj);
    else {
        const tmp = thj - Math.PI * (thj / Math.abs(thj));
        thr = li * thi + lj * tmp;
        thr /= (li + lj);
    }

    const sin_thr = Math.sin(thr)
    const cos_thr = Math.cos(thr)

    const axg = (ay - yg) * sin_thr + (ax - xg) * cos_thr;
    const bxg = (by - yg) * sin_thr + (bx - xg) * cos_thr;
    const cxg = (cy - yg) * sin_thr + (cx - xg) * cos_thr;
    const dxg = (dy - yg) * sin_thr + (dx - xg) * cos_thr;

    const delta1xg = Math.min(axg,Math.min(bxg,Math.min(cxg,dxg)));
    const delta2xg = Math.max(axg,Math.max(bxg,Math.max(cxg,dxg)));

    const delta1x = delta1xg * cos_thr + xg;
    const delta1y = delta1xg * sin_thr + yg;
    const delta2x = delta2xg * cos_thr + xg;
    const delta2y = delta2xg * sin_thr + yg;

    return [delta1x, delta1y, delta2x, delta2y]
}

export const mergeLines = (lineA:Line, lineB:Line) => {
    const points = mergeLinePoints(lineA.x1, lineA.y1, lineA.x2, lineA.y2, lineB.x1, lineB.y1, lineB.x2, lineB.y2);
    return new Line(points);
}